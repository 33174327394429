const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.011}
    height={16.769}
    {...props}
  >
    <path
      d="m1.061 1.061 7.2 7.328-7.191 7.32"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgComponent;

import React from "react";
import styled from "styled-components";
import { Text } from "./Text";

const StyledButton = styled.button`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(146)}px` : `${theme.getValue(260)}px`};
  padding-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(4.5)}px` : `${theme.getValue(8)}px`};
  padding-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(4.5)}px` : `${theme.getValue(8)}px`};
  background: transparent;
  border: 1.5px solid #ffffff;
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(28)}px` : `${theme.getValue(28)}px`};
  cursor: pointer;
`;

const StyledText = styled(Text)`
  cursor: pointer;
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(22)}px`
      : `${theme.getFontSize(30)}px`};

  &&& {
    color: #ffffff;
  }
`;

type ButtonProps = {
  label: UIText;
  onClick: () => void;
};

export const Button = ({ label, onClick }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <StyledText text={label} tag={"span"} />
    </StyledButton>
  );
};

export default Button;

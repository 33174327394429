import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ChevronRight from "../svgs/ChrevronRight";
import ChevronLeft from "../svgs/ChrevronLeft";
import { RootState } from "../../store";
import { Text } from "./Text";

const Container = styled.div`
  height: ${({ theme }) =>
    theme.isMobile ? theme.getValue(17) : theme.getValue(27.77)}px;
  min-width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(302) : theme.getValue(1088)}px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: ${({ theme }) =>
    theme.isMobile ? theme.getValue(36) : theme.getValue(56)}px; ;
`;

const BarsContainer = styled.div`
  display: flex;
  width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(214) : theme.getValue(536)}px;
  gap: ${({ theme }) =>
    theme.isMobile ? theme.getValue(2) : theme.getValue(5)}px;
`;

const Bar = styled.div<{
  leftBorder?: boolean;
  selected: boolean;
  rightBorder?: boolean;
  color: string;
}>`
  flex: 1;
  height: ${({ theme }) =>
    theme.isMobile ? theme.getValue(11) : theme.getValue(19.53)}px;
  border: ${({ theme }) =>
      theme.isMobile ? theme.getValue(1.5) : theme.getValue(3)}px
    solid ${({ color }) => color};
  background: ${({ selected, color }) => (selected ? color : "transparent")};
`;

const ArrowLeft = styled(ChevronLeft)<{ color: string }>`
  height: auto;
  svg {
    width: ${({ theme }) =>
      theme.isMobile ? theme.getValue(7.2) : theme.getValue(12.78)}px;
  }

  path {
    stroke: ${({ color }) => color};
  }
`;

const ArrowRight = styled(ChevronRight)<{ color: string }>`
  height: auto;
  svg {
    width: ${({ theme }) =>
      theme.isMobile ? theme.getValue(7.2) : theme.getValue(12.78)}px;
  }

  path {
    stroke: ${({ color }) => color};
  }
`;

const TransparentButton = styled.button<{ disabled?: boolean }>`
  background: transparent;
  border: none;
  display: flex;
  visibility: ${({ disabled }) => disabled && "hidden"};
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) =>
    theme.isMobile ? theme.getValue(8.8) : theme.getValue(19)}px;
`;

const ButtonText = styled(Text)<{ color: string }>`
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(16) : theme.getFontSize(27)}px;
  font-weight: 700;
  position: relative;
  top: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(2) : theme.getFontSize(4)}px;

  &&& {
    color: ${({ color }) => color};
  }
`;

type NavigationBarProps = {
  steps: number;
  currentStep: number;
  accentColor: string;
  onPrevClick: () => void;
  onNextClick: () => void;
};

export const NavigationBar = ({
  steps,
  currentStep,
  onPrevClick,
  onNextClick,
  accentColor,
}: NavigationBarProps) => {
  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const questions = useSelector((store: RootState) => store.quiz.questions);
  const labels = useSelector((store: RootState) => store.quiz.labels);

  const isOptionSelected = React.useMemo(() => {
    const currentQuestion = questions[currentStep];
    return selectedAnswers[currentQuestion.id]?.length > 0;
  }, [questions, selectedAnswers, currentStep]);
  return (
    <Container>
      <TransparentButton onClick={onPrevClick}>
        <ArrowLeft color={accentColor} />
        <ButtonText
          color="transparent"
          id={"embedded-next-button"}
          text={{ value: labels?.next }}
          tag={"label"}
        />
      </TransparentButton>
      <BarsContainer>
        {new Array(steps).fill(1).map((s, i, arr) => (
          <Bar
            key={i}
            leftBorder={i === 0}
            color={accentColor}
            selected={i <= currentStep}
            rightBorder={i === arr.length - 1}
          />
        ))}
      </BarsContainer>
      <TransparentButton
        aria-labelledby={"embedded-next-button"}
        onClick={onNextClick}
        disabled={!isOptionSelected}
      >
        <ButtonText
          color={accentColor}
          id={"embedded-next-button"}
          text={{ value: labels?.next }}
          tag={"label"}
        />
        <ArrowRight color={accentColor} />
      </TransparentButton>
    </Container>
  );
};

export default Text;

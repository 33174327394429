import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Slider } from "../shared/Slider";
import { Lottie } from "../shared/Lottie";
import { getWidth } from "../../utils/ui";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { Text } from "../shared/Text";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  addSelectedAnswers,
  addSelectedAnswersProgress,
} from "../../store/quiz";
import { useDeviceDetector } from "../../hooks/useDeviceDetector";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: center;
  padding: 0;
  margin: ${({ theme }) => `0 0 ${theme.getValue(18)}px`};
`;

const LabelsContainer = styled.div<{
  alignCenter: boolean;
}>`
  display: flex;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? "center" : "space-between"};
  width: 100%;
  pointer-events: none;
`;

const Label = styled.div<{
  isVisible?: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: column;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(53)}px` : `${theme.getValue(5)}px`};
  max-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(110)}px` : `${theme.getValue(260)}px`};
`;

const Title = styled(Text)<{
  align?: string;
  big?: boolean;
  paddingBottom?: boolean;
  paddingTop?: boolean;
}>`
  font-weight: 400;
  font-size: ${({ theme, big }) =>
    theme.isMobile
      ? `${theme.getFontSize(big ? 30 : 18)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: ${(props) => props.align};
  padding-top: ${({ theme, paddingTop }) =>
    theme.isMobile
      ? `${theme.getValue(paddingTop ? 13.9 : 0)}px`
      : `${theme.getValue(paddingTop ? 15.6 : 0)}px`};
  padding-bottom: ${({ theme, paddingBottom }) =>
    theme.isMobile
      ? `${theme.getValue(paddingBottom ? 13.9 : 0)}px`
      : `${theme.getValue(paddingBottom ? 15.6 : 0)}px`};
  margin: 0;
`;

const SubTitle = styled(Text)<{
  big?: boolean;
  align?: string;
  paddingBottom?: boolean;
  paddingTop?: boolean;
}>`
  font-weight: 400;
  font-size: ${({ theme, big }) =>
    theme.isMobile
      ? `${theme.getFontSize(big ? 30 : 18)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: ${(props) => props.align};
  padding: 0;
  padding-top: ${({ theme, paddingTop }) =>
    theme.isMobile
      ? `${theme.getValue(paddingTop ? 13.9 : 0)}px`
      : `${theme.getValue(paddingTop ? 15.6 : 0)}px`};
  padding-bottom: ${({ theme, paddingBottom }) =>
    theme.isMobile
      ? `${theme.getValue(paddingBottom ? 13.9 : 0)}px`
      : `${theme.getValue(paddingBottom ? 15.6 : 0)}px`};
  margin: 0;
`;

const SliderContainer = styled.div`
  position: relative;
  width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(302) : theme.getValue(522)}px;
`;

const StyledSlider = styled(Slider)``;

export const SliderQuestion = () => {
  const [lottieLoaded, setLottieLoaded] = useState(false);
  const { isMobile } = useDeviceDetector();
  const selectedAnswersProgress = useSelector(
    (store: RootState) => store.quiz.selectedAnswersProgress
  );
  const activeQuestions = useSelector(
    (store: RootState) => store.quiz.activeQuestions
  );
  const activeQuestion = useMemo(() => {
    return activeQuestions[activeQuestions.length - 1];
  }, [activeQuestions]);

  const question = useSelector(
    (store: RootState) => store.quiz.questions[activeQuestion || 0]
  ) as SliderQuestion;

  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );

  const selectedAnswerIndex = selectedAnswers[question.id]
    ? question.steps
        .map((s) => s.id)
        .indexOf(selectedAnswers[question.id][0].id)
    : 0;

  const getStepSliderValue = () => {
    const storedProgress = selectedAnswersProgress[question.id];
    if (storedProgress) return storedProgress;
    if (selectedAnswerIndex === 0) return 0;
    if (selectedAnswerIndex === question.steps.length - 1) return 100;
    return selectedAnswerIndex * Math.ceil(100 / (question.steps.length - 1));
  };
  const [sliderValue, setSliderValue] = useState<number>(getStepSliderValue());
  const lottieInstance = useRef<any>();
  const dispatch = useDispatch();
  const containerDimensions = useContainerDimensions();

  const handleSliderChange = useCallback(
    (value: number | readonly number[]) => {
      setSliderValue(value as number);
      dispatch(
        addSelectedAnswersProgress({
          questionId: question.id,
          progress: value as number,
        })
      );
    },
    [question.id, dispatch]
  );

  const handleLoadLottie = useCallback((instance: any) => {
    lottieInstance.current = instance;
    setTimeout(() => {
      setLottieLoaded(true);
    }, 250);
  }, []);

  const currentFrame = useMemo(
    () => {
      if (!lottieLoaded) return 0;
      return Math.floor(
        lottieInstance.current?.animationData?.op
          ? (lottieInstance.current.animationData.op * sliderValue) / 100
          : 0
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sliderValue, lottieLoaded]
  );

  const getStepIndex = useCallback((): number => {
    const getNearestIndex = () => {
      let nearest = 0;
      let diff = Infinity;
      const increment = Math.ceil(100 / (question?.steps.length - 1));
      for (let index = 0; index < question?.steps.length; index++) {
        let value;
        if (index === 0) {
          value = 0;
        } else if (index === question?.steps.length - 1) {
          value = 100;
        } else {
          value = increment * index;
        }
        const calculatedDiff = Math.abs(value - sliderValue);
        if (calculatedDiff < diff) {
          diff = calculatedDiff;
          nearest = index;
        }
      }
      return nearest;
    };
    return getNearestIndex();
  }, [question, sliderValue]);

  const labelSteps = useMemo(() => {
    let leftStep,
      middleStep,
      rightStep = null;
    if (question?.steps[0]) {
      leftStep = question?.steps[0];
    }

    const index = getStepIndex();
    if (
      sliderValue > 0 &&
      sliderValue < 100 &&
      index !== 0 &&
      index !== question?.steps.length - 1
    ) {
      if (index && question?.steps[index]) {
        middleStep = question?.steps[index];
      }
    }
    if (question?.steps[question?.steps?.length - 1]) {
      rightStep = question?.steps[question?.steps?.length - 1];
    }
    return {
      left: leftStep as SliderStep | null,
      middle: middleStep as SliderStep | null,
      right: rightStep as SliderStep | null,
    };
  }, [question, sliderValue, getStepIndex]);

  const getSelectedAnswer = useCallback(() => {
    const index = getStepIndex();

    return question.steps[index];
  }, [getStepIndex, question]);

  const existMiddleText = !!(
    labelSteps.middle?.title?.value || labelSteps.middle?.subtitle?.value
  );

  useEffect(() => {
    const selectedAnswer = getSelectedAnswer();
    if (!selectedAnswer) {
      return;
    }
    const auxSelectedAnswers: Array<BaseAnswer> = [selectedAnswer];
    dispatch(
      addSelectedAnswers({
        questionId: question?.id,
        answers: auxSelectedAnswers,
      })
    );
    return;
  }, [getSelectedAnswer, question, dispatch]);

  const showCurrentStepLabel = getSelectedAnswer().showAnswerText || false;

  return (
    <Container>
      <Header text={question?.title} tag={"h1"} />
      <Lottie
        width={getWidth({
          containerDimensions,
          isMobile,
          value: isMobile ? 375 : 536,
        })}
        height={getWidth({
          containerDimensions,
          isMobile,
          value: isMobile ? 180 : 460,
        })}
        url={question?.lottieAnimation}
        autoplay={false}
        frame={currentFrame}
        onLoad={handleLoadLottie}
      />
      <SliderContainer>
        <LabelsContainer alignCenter={existMiddleText && showCurrentStepLabel}>
          {labelSteps?.left && (!existMiddleText || !showCurrentStepLabel) && (
            <Label isVisible>
              <SubTitle
                big={isMobile}
                align={"left"}
                text={labelSteps.left.subtitle as UIText}
                tag={"span"}
              />
              {!isMobile && (
                <Title
                  big={!!labelSteps.left.subtitle?.value}
                  align={"left"}
                  text={labelSteps.left.title as UIText}
                  tag={"span"}
                  paddingBottom
                />
              )}
            </Label>
          )}
          {existMiddleText && (
            <Label isVisible={showCurrentStepLabel}>
              <SubTitle
                big={isMobile}
                text={labelSteps?.middle?.subtitle as UIText}
                align="center"
                tag={"span"}
              />
              {!isMobile && (
                <Title
                  text={labelSteps?.middle?.title as UIText}
                  align="center"
                  tag={"span"}
                  paddingBottom
                />
              )}
            </Label>
          )}
          {labelSteps?.right && (!existMiddleText || !showCurrentStepLabel) && (
            <Label isVisible>
              <SubTitle
                big={isMobile}
                align={"right"}
                text={labelSteps.right.subtitle as UIText}
                tag={"span"}
              />
              {!isMobile && (
                <Title
                  big={!!labelSteps.right.subtitle?.value}
                  align={"right"}
                  text={labelSteps.right.title as UIText}
                  tag={"span"}
                  paddingBottom
                />
              )}
            </Label>
          )}
        </LabelsContainer>
        <StyledSlider
          accentColor={question.accentColor || "#ffffff"}
          step={1}
          value={sliderValue}
          onChange={handleSliderChange}
        />

        <LabelsContainer alignCenter={existMiddleText && showCurrentStepLabel}>
          {labelSteps?.left && (!existMiddleText || !showCurrentStepLabel) && (
            <Label isVisible>
              {isMobile && (
                <Title
                  align={"left"}
                  text={labelSteps.left.title as UIText}
                  tag={"span"}
                  paddingTop
                />
              )}
            </Label>
          )}
          {existMiddleText && (
            <Label isVisible={showCurrentStepLabel}>
              {isMobile && (
                <Title
                  text={labelSteps?.middle?.title as UIText}
                  align="center"
                  tag={"span"}
                  paddingTop
                />
              )}
            </Label>
          )}
          {labelSteps?.right && (!existMiddleText || !showCurrentStepLabel) && (
            <Label isVisible>
              {isMobile && (
                <Title
                  align={"right"}
                  text={labelSteps.right.title as UIText}
                  tag={"span"}
                  paddingTop
                />
              )}
            </Label>
          )}
        </LabelsContainer>
      </SliderContainer>
    </Container>
  );
};

export default SliderQuestion;

const session = null;

const parseProduct = ({ uuid, productUuid, sku, productData }: any) => {
  try {
    return {
      product_id: uuid || productUuid,
      product_kind: productData?.productType,
      product_sku: sku,
    };
  } catch (e) {
    return {};
  }
};

const parseAnswer = ({ uuid, selectedText }: any) => ({
  answer_id: uuid,
  answer_title: selectedText,
});

const parseQuestion = ({ uuid, pageID, title }: any) => ({
  question_id: uuid || pageID,
  question_text: title,
});

export const onSelectAnswer = (
  pixel: any = () => {},
  currentAnswer = {},
  answers = []
) => {
  try {
    pixel("event", "answerClick", {
      session,
      ...parseQuestion(currentAnswer),
      selected_answers: answers && answers.map((answer) => parseAnswer(answer)),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onLoadResults = (
  pixel: any = () => {},
  resultsProducts = [],
  answers = []
) => {
  try {
    pixel("event", "resultsLoad", {
      session,
      products:
        resultsProducts &&
        resultsProducts.map((product) => parseProduct(product)),
      selected_answers: answers && answers.map((answer) => parseAnswer(answer)),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onClickProduct = (
  pixel: any = () => {},
  trackingProductData: any = {}
) => {
  const { productData: fullProduct } = trackingProductData;

  try {
    pixel("event", "productClick", {
      session,
      product: parseProduct(fullProduct),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onEmailSubmit = (pixel: any = () => {}, sourceCta = "") => {
  try {
    pixel("event", "emailSubmit", {
      session,
      ctaLabel: sourceCta,
    });
  } catch (error) {
    console.error(error);
  }
};

import { memo, useEffect, useRef, useState } from "react";
import lottie from "lottie-web";

interface LottieProps {
  url: string;
  width: number | string;
  height: number | string;
  playing?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  frame?: number;
  onLoopComplete?: () => void;
  onLoad?: (lottieRef: any) => void;
  onComplete?: () => void;
  className?: string;
}

export const Lottie = ({
  url,
  width,
  height,
  playing,
  autoplay = false,
  loop = false,
  frame,
  onLoopComplete,
  onLoad,
  onComplete,
  className,
}: LottieProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (playing && typeof frame === "number") {
      lottieInstance?.current?.goToAndPlay(frame, true);
      return;
    }
    if (!playing && typeof frame === "number") {
      lottieInstance?.current?.goToAndStop(frame, true);
      return;
    }
    if (playing) {
      lottieInstance?.current?.play();
      return;
    }
    if (!playing) {
      lottieInstance?.current?.stop();
      return;
    }
  }, [playing, frame, loaded]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (onLoopComplete) {
      lottieInstance?.current?.addEventListener("loopComplete", onLoopComplete);
    }
    if (onComplete) {
      lottieInstance?.current?.addEventListener("complete", onComplete);
    }
    return () => {};
  }, [onLoopComplete, onComplete, loaded]);

  useEffect(() => {
    if (element.current && !loaded && url) {
      lottieInstance.current = lottie.loadAnimation({
        path: url,
        container: element.current,
        autoplay,
        loop,
      });
      setLoaded(true);
      if (onLoad) {
        onLoad(lottieInstance.current);
      }
    }
  }, [url, loaded, autoplay, loop, onLoopComplete, onLoad]);

  return (
    <div className={className} style={{ width, height }} ref={element}></div>
  );
};

export default memo(Lottie);

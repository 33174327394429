import React from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { Image } from "../shared/Image";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(1640)}px`};
`;

const Section = styled.a`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(5)}px`};
  text-decoration: none;
`;
const RightSection = styled(Section)`
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(41)}px ${theme.getValue(35)}px ${theme.getValue(
          44
        )}px`
      : `${theme.getValue(55)}px ${theme.getValue(414)}px ${theme.getValue(
          40
        )}px ${theme.getValue(55)}px`};
`;

const LeftSection = styled(Section)`
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(41)}px ${theme.getValue(35)}px ${theme.getValue(
          44
        )}px`
      : `${theme.getValue(55)}px ${theme.getValue(55)}px ${theme.getValue(
          40
        )}px ${theme.getValue(414)}px`};
`;

const Border = styled.div`
  width: 2px;
  height: auto;
  background-color: #dce9d5;
`;

const Label = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(20)}px`
      : `${theme.getFontSize(24)}px`};
  text-align: center;
  margin: 0;

  &&& {
    color: #447355;
  }
`;

const StyledImage = styled(Image)`
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(35)}px` : `${theme.getValue(35)}px`};
  width: auto;
`;

type ShareProps = {
  leftSectionTitle: UIText;
  leftSectionImage: string;
  leftSectionLink: string;
  rightSectionTitle: UIText;
  rightSectionImage: string;
  rightSectionLink: string;
};

export const Share = ({
  leftSectionLink,
  leftSectionImage,
  leftSectionTitle,
  rightSectionImage,
  rightSectionLink,
  rightSectionTitle,
}: ShareProps) => {
  return (
    <Container>
      <LeftSection href={leftSectionLink} target={"_blank"}>
        <StyledImage src={leftSectionImage} alt={leftSectionTitle?.value} />
        <Label text={leftSectionTitle} />
      </LeftSection>
      <Border />
      <RightSection href={rightSectionLink} target={"_blank"}>
        <StyledImage src={rightSectionImage} alt={rightSectionTitle?.value} />
        <Label text={rightSectionTitle} />
      </RightSection>
    </Container>
  );
};

export default Share;

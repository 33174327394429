const IconCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.487"
    height="15.276"
    viewBox="0 0 18.487 15.276"
    {...props}
  >
    <g
      id="Component_89_1"
      data-name="Component 89 – 1"
      transform="translate(2.828 2.823)"
    >
      <path
        id="Path_6210"
        data-name="Path 6210"
        d="M124.237,266.839l3.645,3.782,9.191-10.453"
        transform="translate(-124.237 -260.167)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default IconCheck;

export const EMPTY_UI_TEXT: UIText = {
  value: "",
};

export const EMPTY_UI_BUTTON: UIButton = {
  label: EMPTY_UI_TEXT,
};

export default {
  EMPTY_UI_TEXT,
  EMPTY_UI_BUTTON,
};

import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counter";
import uiReducer from "./ui";
import quizReducer from "./quiz";
import routerReducer from "./router";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    ui: uiReducer,
    quiz: quizReducer,
    router: routerReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const getFrame = (
  step: number,
  totalSteps: number,
  totalFrames: number
): number => {
  if (step === 0) {
    return 0;
  }
  if (step === totalSteps) {
    return totalFrames;
  }
  if (totalSteps <= 1) {
    return totalFrames;
  }
  const delta = totalFrames / (totalSteps - 1);
  return Math.ceil(step * delta);
};

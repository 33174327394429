import styled from "styled-components";
import { FontFamily, FontWeight } from "../../types/fonts";

export default styled.div<{
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;
  fontColor?: string;
}>`
  &&& {
    font-family: ${({ fontFamily }) => fontFamily};
    font-weight: ${({ fontWeight }) => fontWeight};
    font-size: ${({ theme }) =>
      theme.isMobile
        ? `${theme.getFontSize(18)}px`
        : `${theme.getFontSize(20)}px`};
    text-align: left;
    line-height: 1.1;
    color: ${({ fontColor }) => fontColor};

    p,
    a,
    li {
      color: ${({ fontColor }) => fontColor};
    }

    p {
      line-height: 1.1;
      margin: 0;
    }

    ul {
      margin: 0;
      padding-left: ${({ theme }) => theme.getValue(24)}px;
    }

    li {
      line-height: 1.1;
    }

    li + li {
      margin-top: ${({ theme }) => theme.getValue(4)}px;
    }

    strong {
      font-weight: 700;
    }

    .ql-size-small {
      font-size: ${({ theme }) =>
        theme.isMobile
          ? `${theme.getFontSize(12)}px`
          : `${theme.getFontSize(16)}px`};
    }

    .ql-size-large {
      font-size: ${({ theme }) =>
        theme.isMobile
          ? `${theme.getFontSize(28)}px`
          : `${theme.getFontSize(32)}px`};
    }

    .ql-size-huge {
      font-size: ${({ theme }) =>
        theme.isMobile
          ? `${theme.getFontSize(32)}px`
          : `${theme.getFontSize(40)}px`};
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-right {
      text-align: right;
    }
  }
`;

import React, { useMemo } from "react";
import styled from "styled-components";

const StyledImage = styled.img``;

const MEDIA_URL_REGEX =
  /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)(.+)/;

function extractUrlMetadata(url: string) {
  const match = url.match(MEDIA_URL_REGEX);
  const domain = match?.[1];
  const path = match?.[2];
  return {
    domain,
    path,
  };
}

type ImageProps = {
  src?: string;
  alt?: string;
  className?: string;
  responsiveWidth?: number;
};

export const Image = ({
  src,
  alt,
  className,
  responsiveWidth,
  ...props
}: ImageProps) => {
  const responsiveSrc = useMemo(() => {
    let newSrc = src;
    if (src && responsiveWidth) {
      const { domain, path } = extractUrlMetadata(src);
      if (domain && path) {
        newSrc = `https://${domain}/cdn-cgi/image/width=${responsiveWidth}${path}`;
      }
    }
    return newSrc;
  }, [responsiveWidth, src]);
  return (
    <StyledImage
      src={responsiveSrc}
      alt={alt}
      className={className}
      {...props}
    />
  );
};

export default Image;

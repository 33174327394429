import React from "react";
import styled from "styled-components";

const LargeButton = styled.button<{
  fontColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  fontFamily?: string;
}>`
  font-size: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(22) : theme.getFontSize(30)}px;
  font-weight: 700;
  border: ${({ theme }) => theme.getValue(1.5)}px solid #ffffff;
  border-radius: ${({ theme }) =>
    theme.isMobile ? theme.getValue(22) : theme.getFontSize(31)}px;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(8)}px ${theme.getValue(22)}px`
      : `${theme.getValue(15)}px ${theme.getValue(26)}px`};
  width: fit-content;
  color: ${({ fontColor }) => fontColor};
  background: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  font-family: ${({ fontFamily }) => fontFamily};

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }
`;

const SmallButton = styled(LargeButton)`
  font-size: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(18) : theme.getFontSize(24)}px;
  font-weight: 400;
  border: ${({ theme }) => theme.getValue(2)}px solid #ffffff;
  border-radius: ${({ theme }) =>
    theme.isMobile ? theme.getValue(28) : theme.getFontSize(31)}px;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(7)}px ${theme.getValue(22)}px`
      : `${theme.getValue(18.5)}px ${theme.getValue(26)}px`};
`;

type RoundedButtonProps = {
  children?: React.ReactNode;
  onClick: () => void;
  fontColor?: string;
  hoverBackgroundColor?: string;
  backgroundColor?: string;
  label: UIText;
  size?: "small" | "large";
  className?: string;
};

export const RoundedButton = ({
  onClick,
  fontColor = "#ffffff",
  hoverBackgroundColor,
  backgroundColor = "transparent",
  label,
  size = "large",
  className,
}: RoundedButtonProps) => {
  const Component = size === "large" ? LargeButton : SmallButton;
  return (
    <Component
      className={className}
      onClick={onClick}
      fontColor={fontColor}
      hoverBackgroundColor={hoverBackgroundColor}
      backgroundColor={backgroundColor}
      fontFamily={label?.fontFamily}
    >
      {label?.value}
    </Component>
  );
};

export default RoundedButton;

import axios from "axios";
import { SignUpDataType } from "./types.d";

export const fetchExperience = async (apiUrl: string, experienceId: string) => {
  const response = await fetch(
    `${apiUrl}/external/quiz_partners/${experienceId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.json();
};

export const saveResults = (data: SignUpDataType) => {
  const { apiUrl, experienceId } = document.getElementById(
    "irobot-embedded-quiz-v4"
  )?.dataset as { apiUrl: string; experienceId: string };
  return axios.post(`${apiUrl}/quiz_experience/contact_form`, {
    ...data,
    quiz_partner_uuid: experienceId,
  });
};

import React from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { Image } from "../shared/Image";
import RichText from "./RichText";

const Container = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? `column` : `row`)};
  justify-content: center;
  flex: 1;
  background-color: ${({ theme }) => (theme.isMobile ? `#DCE9D5` : `#FFFFFF`)};
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(1640)}px`};
  flex-wrap: wrap;
  padding-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(0)}px`};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(82)}px` : `${theme.getValue(102)}px`};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(950)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(40)}px ${theme.getValue(37)}px`
      : `${theme.getValue(60)}px ${theme.getValue(138)}px`};
  align-items: ${({ theme }) => (theme.isMobile ? `center` : `flex-start`)};
`;

const Header = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: ${({ theme }) => (theme.isMobile ? `center` : `left`)};
  margin: 0;
`;

const StyledImage = styled(Image)`
  margin: 0;
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(375)}px` : `${theme.getValue(690)}px`};
  min-height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(174)}px` : `${theme.getValue(473)}px`};
  object-fit: cover;
  height: auto;
`;

const Link = styled.a<{ backgroundColor?: string }>`
  text-align: center;
  min-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(166)}px` : `${theme.getValue(260)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(7)}px ${theme.getValue(29)}px`
      : `${theme.getValue(18.5)}px ${theme.getValue(56)}px`};

  background-color: ${({ backgroundColor }) => backgroundColor || "#447355"};
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(28)}px` : `${theme.getValue(28)}px`};
  text-decoration: none;
  width: fit-content;
`;

const LinkText = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  text-align: center;
  margin: 0;
`;

type Banner2Props = Banner;

export const Banner2 = ({
  title,
  description,
  image1,
  button,
}: Banner2Props) => {
  return (
    <Container>
      <StyledImage src={image1?.src} alt={image1.alt} />
      <TextContainer>
        <Header text={title} />
        <RichText
          fontFamily={description.fontFamily}
          fontWeight={description.fontWeight}
          fontColor={description.fontColor}
          dangerouslySetInnerHTML={{ __html: description.value }}
        />
        {button?.link && (
          <Link
            backgroundColor={button.backgroundColor}
            target={"_blank"}
            href={button?.link}
          >
            <LinkText text={button?.label} tag={"span"} />
          </Link>
        )}
      </TextContainer>
    </Container>
  );
};

export default Banner2;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Dropdown } from "../shared/Dropdown";
import { Lottie } from "../shared/Lottie";
import { getWidth } from "../../utils/ui";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { Button } from "../shared/Button";
import { getFrame } from "../../utils/lottie";
import { addSelectedAnswers } from "../../store/quiz";
import { useDeviceDetector } from "../../hooks/useDeviceDetector";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: center;
  padding: 0;
  margin: ${({ theme }) => `0 0 ${theme.getValue(18)}px`};
`;

const QuestionTitle = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: center;
  padding: 0;
  margin: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(36)}px` : `${theme.getValue(0)}px`};
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
  align-items: center;
`;

const Content = styled.div`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(302)}px` : `${theme.getValue(812)}px`};
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? `column` : `row`)};
  align-items: center;
`;

const ButtomWrapper = styled.div<{ color: string }>`
  button {
    border-color: ${({ color }) => color};
  }

  span {
    color: ${({ color }) => color};
  }
`;

export const DropdownQuestion = () => {
  const activeQuestions = useSelector(
    (store: RootState) => store.quiz.activeQuestions
  );
  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const activeQuestion = useMemo(() => {
    return activeQuestions[activeQuestions.length - 1];
  }, [activeQuestions]);

  const question = useSelector(
    (store: RootState) => store.quiz.questions[activeQuestion || 0]
  ) as DropdownQuestion;

  const selectedOptionIndex = selectedAnswers[question.id]
    ? question.options
        .map((o) => o.id)
        .indexOf(selectedAnswers[question.id][0].id)
    : 0;

  const [selectedOption, setSelectedOption] = useState(
    question.options[selectedOptionIndex]
  );

  const { isMobile } = useDeviceDetector();
  const containerDimensions = useContainerDimensions();
  //
  const lottieInstance = useRef<any>();
  const currentFrame = useRef<number>(0);

  const [isOpen, setOpen] = useState(false);
  const [lottieLoaded, setLottieLoaded] = useState(false);

  const handleLoadLottie = useCallback((instance: any) => {
    lottieInstance.current = instance;
    setTimeout(() => {
      setLottieLoaded(true);
    }, 250);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lottieLoaded) {
      const optionIndex = question?.options?.findIndex(
        (o) => o.id === selectedOption?.id
      );
      const to =
        getFrame(
          optionIndex,
          question.options.length,
          lottieInstance.current?.animationData?.op
        ) || 0;

      if (currentFrame.current !== to)
        lottieInstance.current?.playSegments([currentFrame.current, to], true);
      currentFrame.current = to;
    }
  }, [selectedOption, question?.options, lottieLoaded]);

  const handleOptionSelect = useCallback(
    (option: DropdownOption) => {
      setSelectedOption(option);
      setOpen(false);
      dispatch(
        addSelectedAnswers({
          questionId: question.id,
          answers: [option],
        })
      );
    },
    [question, setOpen, setSelectedOption, dispatch]
  );

  useEffect(() => {
    if (selectedOption && !selectedAnswers[question.id]) {
      handleOptionSelect(selectedOption);
    }
  }, [question, handleOptionSelect, selectedAnswers, selectedOption]);

  return (
    <Container>
      <Header text={question?.title} tag={"h1"} />
      <Content>
        <Lottie
          width={getWidth({
            containerDimensions,
            isMobile,
            value: isMobile ? 302 : 650,
          })}
          height={getWidth({
            containerDimensions,
            isMobile,
            value: isMobile ? 240 : 563,
          })}
          url={question?.lottieAnimation}
          autoplay={false}
          frame={1}
          onLoad={handleLoadLottie}
        />
        <TextContainer>
          <QuestionTitle text={question?.optionsTitle} tag={"p"} />
          <ButtomWrapper color={question?.optionsTitle?.fontColor || "#ffffff"}>
            <Button
              label={selectedOption?.label}
              onClick={() => setOpen(true)}
            />
          </ButtomWrapper>
        </TextContainer>
      </Content>
      {isOpen && (
        <Dropdown
          onClose={() => setOpen(false)}
          title={question?.optionsTitle}
          options={question?.options}
          selectedOption={selectedOption}
          answerModalBackgroundColor={question?.answerModalBackgroundColor}
          answerModalSelectedColor={question?.answerModalSelectedColor}
          answerModalSubtitleColor={question?.answerModalSubtitleColor}
          answerModalTextColor={question?.answerModalTextColor}
          onClick={handleOptionSelect}
        />
      )}
    </Container>
  );
};

export default DropdownQuestion;

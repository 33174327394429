import React from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";

const Container = styled.a<{ backgroundColor: string }>`
  border: none;
  background: ${({ backgroundColor }) => backgroundColor || "#447355"};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: ${({ theme }) => `${theme.getValue(22)}px`};
  width: ${({ theme }) => `${theme.getValue(146)}px`};
  min-width: ${({ theme }) => `${theme.getValue(146)}px`};
  overflow: hidden;
  text-decoration: none;
`;

const Header = styled(Text)<{ backgroundColor: string; color?: string }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: ${({ backgroundColor }) => backgroundColor || "#447355"};
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(16)}px`
      : `${theme.getFontSize(18)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(12)}px ${theme.getValue(8)}px ${theme.getValue(9)}px`
      : `${theme.getFontSize(18)}px`};
  text-align: center;
  margin: 0;
  width: 100%;
  text-transform: uppercase;

  &&& {
    color: ${({ color }) => color || "#ffffff"};
  }
`;

const Image = styled.img`
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(102)}px` : `${theme.getValue(102)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(138)}px` : `${theme.getValue(116)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(0)}px` : `${theme.getValue(20)}px`};
  object-fit: contain;
  object-position: center;
`;

type ProductCategoryProps = {
  backgroundColor: string;
  headerBackgroundColor: string;
  headerTextColor: string;
  image: string;
  text: UIText;
  link?: string;
};

export const ProductCategory = ({
  headerBackgroundColor,
  backgroundColor,
  headerTextColor,
  image,
  text,
  link,
}: ProductCategoryProps) => {
  return (
    <Container href={link} backgroundColor={backgroundColor}>
      <Header
        backgroundColor={headerBackgroundColor}
        color={headerTextColor}
        text={text}
        tag={"p"}
      />
      <Image src={image} alt={text?.value} />
    </Container>
  );
};

export default ProductCategory;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { GraphicToggle } from "../shared/GraphicToggle";
import { addSelectedAnswers } from "../../store/quiz";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled(Text)<{ withMarginBottom: boolean }>`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: ${({ theme, withMarginBottom }) =>
    theme.isMobile
      ? `${theme.getValue(withMarginBottom ? 20 : 0)}px`
      : `${theme.getValue(withMarginBottom ? 120 : 0)}px`};
`;

const SubHeader = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(8)}px` : `${theme.getValue(20)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(120)}px`};
`;

const Content = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: ${({ theme }) =>
    theme.isMobile ? "1fr" : "1fr 1fr 1fr"};
  grid-column-gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(0)}px` : `${theme.getValue(16)}px`};
  grid-row-gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(15)}px` : `${theme.getValue(35)}px`};
  grid-auto-rows: 1fr;
  margin: auto;

  & > * {
    margin-left: ${({ theme }) => (theme.isMobile ? "auto" : "unset")};
    margin-right: ${({ theme }) => (theme.isMobile ? "auto" : "unset")};
  }
`;

export const IllustrationQuestion = () => {
  const activeQuestions = useSelector(
    (store: RootState) => store.quiz.activeQuestions
  );
  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const activeQuestion = useMemo(() => {
    return activeQuestions[activeQuestions.length - 1];
  }, [activeQuestions]);

  const question = useSelector(
    (store: RootState) => store.quiz.questions[activeQuestion || 0]
  ) as IllustrationQuestion;

  const [selectedOptions, setSelectedOptions] = useState<
    Array<IllustrationOption>
  >((selectedAnswers[question.id] as IllustrationOption[]) || []);

  const dispatch = useDispatch();

  const handleSelectOption = useCallback(
    (option: IllustrationOption) => {
      const optionIndex = selectedOptions.findIndex((o) => o.id === option.id);
      if (!question.isSingleAnswer) {
        if (optionIndex === -1) {
          setSelectedOptions([...selectedOptions, option]);
          return;
        }
        const newOptions = [...selectedOptions];
        newOptions.splice(optionIndex, 1);
        setSelectedOptions(newOptions);
      } else {
        setSelectedOptions(
          selectedOptions.map((so) => so.id).includes(option.id) ? [] : [option]
        );
      }
    },
    [selectedOptions, question.isSingleAnswer]
  );

  useEffect(() => {
    dispatch(
      addSelectedAnswers({
        questionId: question?.id,
        answers: selectedOptions,
      })
    );
  }, [selectedOptions, question, dispatch]);

  return (
    <Container>
      <Header
        withMarginBottom={question.isSingleAnswer || !question?.subtitle}
        text={question?.title}
        tag={"h1"}
      />
      {!question.isSingleAnswer && (
        <SubHeader text={question?.subtitle} tag={"h2"} />
      )}
      <Content>
        {question?.options?.map((o, index) => (
          <GraphicToggle
            key={o.id}
            text={o.text}
            buttonColor={o.buttonColor || "#DCE9D5"}
            buttonHoverColor={o.buttonHoverColor || "#ffffff"}
            buttonTextColor={o.buttonTextColor || "#000000"}
            isActive={selectedOptions.map((so) => so.id).includes(o.id)}
            activeImage={o.activeImage}
            nonActiveImage={o.nonActiveImage}
            onClick={() => {
              handleSelectOption(o);
            }}
            direction={index % 2 === 0 ? "LEFT" : "RIGHT"}
          />
        ))}
      </Content>
    </Container>
  );
};

export default IllustrationQuestion;

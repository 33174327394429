import React from "react";
import styled from "styled-components";

const LargeButton = styled.a<{
  fontColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  fontFamily?: string;
}>`
  font-size: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(22) : theme.getFontSize(30)}px;
  font-weight: 700;
  border-radius: ${({ theme }) =>
    theme.isMobile ? theme.getValue(22) : theme.getFontSize(31)}px;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(8)}px ${theme.getValue(22)}px`
      : `${theme.getValue(15)}px ${theme.getValue(26)}px`};
  width: fit-content;
  color: ${({ fontColor }) => fontColor};
  background: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: none;
`;

const SmallButton = styled(LargeButton)`
  font-size: ${({ theme }) =>
    theme.isMobile ? theme.getFontSize(18) : theme.getFontSize(24)}px;
  font-weight: 400;
  border: ${({ theme, borderColor }) =>
    `${theme.getValue(1.5)}px solid ${borderColor || "#ffffff"}`};
  border-radius: ${({ theme }) =>
    theme.isMobile ? theme.getValue(28) : theme.getFontSize(31)}px;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(7)}px ${theme.getValue(22)}px`
      : `${theme.getValue(18.5)}px ${theme.getValue(26)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(175) : theme.getValue(250)}px;
`;

type RoundedButtonLinkProps = {
  children?: React.ReactNode;
  link: string;
  fontColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  label: UIText;
  size?: "small" | "large";
  className?: string;
};

export const RoundedButtonLink = ({
  link,
  fontColor = "#ffffff",
  backgroundColor = "transparent",
  label,
  size = "large",
  borderColor,
  className,
}: RoundedButtonLinkProps) => {
  const Component = size === "large" ? LargeButton : SmallButton;
  return (
    <Component
      className={className}
      href={link}
      fontColor={fontColor}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      fontFamily={label?.fontFamily}
      target={"_blank"}
    >
      {label?.value}
    </Component>
  );
};

export default RoundedButtonLink;

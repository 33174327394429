import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ChevronRight from "../components/svgs/ChrevronRight";
import { Text } from "../components/shared/Text";
import { Lottie } from "../components/shared/Lottie";
import { Image } from "../components/shared/Image";
import { getWidth } from "../utils/ui";
import { useContainerDimensions } from "../hooks/useContainerDimensions";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import { Route } from "../types/routes.d";
import { useDeviceDetector } from "../hooks/useDeviceDetector";

const Container = styled.div<{
  backgroundColor?: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor || "#518A70"};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(65)}px ${theme.getValue(6)}px ${theme.getValue(
          160
        )}px`
      : `${theme.getValue(29)}px ${theme.getValue(538)}px ${theme.getValue(
          133
        )}px`};
  position: relative;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(22)}px`
      : `${theme.getFontSize(48)}px`};
  width: 100%;
  max-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(302)}px` : `100%`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(44)}px` : `${theme.getValue(24)}px`};
`;

const Subtitle = styled(Text)<{ color: string; strokeColor: string }>`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(45)}px`
      : `${theme.getFontSize(72)}px`};
  line-height: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(40)}px`
      : `${theme.getFontSize(72)}px`};
  text-transform: uppercase;
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
  margin: 0;
  max-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(224)}px` : `100%`};
  text-align: ${({ theme }) => (theme.isMobile ? `left` : `left`)};

  &&& {
    color: ${({ color }) => color};
  }
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(16)}px`
      : `${theme.getFontSize(24)}px`};
  width: 100%;
  margin: 0;
  max-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(224)}px` : `${theme.getValue(398)}px`};
  text-align: ${({ theme }) => (theme.isMobile ? `left` : `left`)};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
`;

const Card = styled.div<{ backgroundColor: string }>`
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(60)}px ${theme.getValue(6)}px ${theme.getValue(
          60
        )}px ${theme.getValue(29)}px;`
      : `${theme.getValue(98)}px ${theme.getValue(113)}px`};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(30)}px` : `${theme.getValue(30)}px`};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(5)}px` : `${theme.getValue(16)}px`};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(5)}px` : `${theme.getValue(23)}px`};
`;

const StyledImage = styled(Image)`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(103)}px` : `${theme.getValue(162)}px`};
  height: auto;
  object-fit: contain;
`;

const Continue = styled.button<{ fontFamily?: string; color?: string }>`
  display: flex;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(9)}px` : `${theme.getValue(19)}px`};
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(16)}px`
      : `${theme.getFontSize(27)}px`};
  position: absolute;
  bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(30)}px` : `${theme.getValue(56)}px`};
  right: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(37)}px` : `${theme.getValue(416)}px`};
  background-color: transparent;
  color: ${({ color }) => color || "#ffffff"};
  font-family: ${({ fontFamily }) => fontFamily || "HarmoniaSansStd"};
  border: none;
  align-items: center;
  cursor: pointer;
`;

const ArrowRight = styled(ChevronRight)<{ color: string }>`
  height: auto;
  svg {
    width: ${({ theme }) =>
      theme.isMobile ? `${theme.getValue(7)}px` : `${theme.getValue(13)}px`};
    height: auto;
  }

  path {
    stroke: ${({ color }) => color};
  }
`;

export const Loading = () => {
  const { isMobile } = useDeviceDetector();
  const loadingData = useSelector(
    (store: RootState) => store.quiz.loading
  ) as LoadingScreen;
  const labels = useSelector((store: RootState) => store.quiz.labels);
  const lottieInstance = useRef<any>();
  const containerDimensions = useContainerDimensions();
  const [mounted, setMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleLoadLottie = useCallback((instance: any) => {
    lottieInstance.current = instance;
  }, []);

  const handleContinueClick = useCallback(() => {
    navigate(Route.Results);
  }, [navigate]);

  useEffect(() => {
    const tId = setTimeout(() => {
      setLoading(false);
    }, (loadingData.duration || 5) * 1000);

    return () => {
      clearTimeout(tId);
    };
  }, [setLoading, loadingData.duration]);

  useEffect(() => {
    if (!loading) {
      lottieInstance?.current?.destroy();
    }
  }, [loading]);

  useEffect(() => {
    if (loadingData && !loadingData.showLoadingScreen) {
      navigate(Route.Results);
    }
    if (loadingData) {
      setMounted(true);
    }
  }, [loadingData, navigate]);

  if (!loadingData.showLoadingScreen || !mounted) return <></>;

  return (
    <Container backgroundColor={loadingData?.backgroundColor || "#518A70"}>
      <Lottie
        width={getWidth({
          containerDimensions,
          isMobile,
          value: isMobile ? 72 : 72,
        })}
        height={getWidth({
          containerDimensions,
          isMobile,
          value: isMobile ? 88 : 88,
        })}
        url={loadingData?.animation}
        autoplay={true}
        onLoad={handleLoadLottie}
        loop
      />
      <Title
        tag={"h1"}
        text={loading ? loadingData?.title : loadingData?.notLoadingText}
      />
      <Card backgroundColor={loadingData?.cardBackgroundColor || "#447355"}>
        <Subtitle
          color={loadingData?.cardBackgroundColor || "#447355"}
          strokeColor={loadingData?.subtitle.fontColor || "#dce9d5"}
          tag={"h2"}
          text={loadingData?.subtitle}
        />
        <Row>
          <Description tag={"p"} text={loadingData?.description} />
          <StyledImage
            alt={loadingData?.title?.value}
            src={loadingData?.image}
          />
        </Row>
      </Card>
      {!loading && (
        <Continue
          fontFamily={loadingData?.nextCopy?.fontFamily}
          color={loadingData?.nextCopy?.fontColor}
          onClick={handleContinueClick}
        >
          {loadingData?.nextCopy?.value || labels?.continue}
          <ArrowRight color={loadingData?.nextCopy?.fontColor || "#ffffff"} />
        </Continue>
      )}
    </Container>
  );
};

export default Loading;

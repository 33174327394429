import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { trackShopLinkClick } from "../../utils/analytics";
import { RoundedButtonLink } from "../shared/RoundedButtonLink";
import { CheckSVG } from "../shared/CheckSVG";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { getWidth } from "../../utils/ui";
import { useDeviceDetector } from "../../hooks/useDeviceDetector";
import { isSafari } from "react-device-detect";
import { Image } from "../shared/Image";

const Container = styled.div<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) => backgroundColor || "#447355"};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: ${({ theme }) => `${theme.getValue(22)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(302)}px` : `${theme.getValue(398)}px`};
  overflow: hidden;
`;

const Header = styled(Text)<{ backgroundColor: string; color?: string }>`
  background: ${({ backgroundColor }) => backgroundColor || "#447355"};
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(12)}px ${theme.getValue(36)}px ${theme.getValue(
          21
        )}px`
      : `${theme.getValue(25.5)}px ${theme.getValue(21)}px `};
  text-align: center;
  margin: 0;
  width: 100%;
  text-transform: uppercase;

  &&& {
    color: ${({ color }) => color || "#ffffff"};
  }
`;

const ProductImage = styled(Image)`
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(170)}px` : `${theme.getValue(250)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(170)}px` : `${theme.getValue(250)}px`};
  object-fit: contain;
  object-position: center;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
`;

const ImageContainer = styled.div`
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(190)}px` : `${theme.getValue(270)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(170)}px` : `${theme.getValue(250)}px`};
`;

const Title = styled(Text)<{ marginTop?: number | string }>`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(22)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: left;
  margin-top: ${({ theme, marginTop }) =>
    theme.isMobile
      ? `${theme.getValue(marginTop ?? 10)}px`
      : `${theme.getValue(marginTop ?? 20)}px`};
  margin-bottom: 0;
  width: 100%;
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(16)}px`
      : `${theme.getFontSize(22)}px`};
  text-align: left;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
  width: 100%;
`;

const Pricing = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(8)}px` : `${theme.getValue(12)}px`};
`;

const Price = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(22)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: left;
  margin: 0;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(30)}px` : `${theme.getValue(44.5)}px`};
`;

const OldPrice = styled(Price)`
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(20)}px`
      : `${theme.getFontSize(28)}px`};
  opacity: 0.4;
  text-decoration: line-through;
`;

const PriceDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(6)}px`};
  width: 100%;
`;

const PriceDescription = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(14)}px`
      : `${theme.getFontSize(16)}px`};
  text-align: left;
  margin: 0;
`;

const PriceImage = styled(ProductImage)`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(47)}px` : `${theme.getValue(57)}px`};
  margin: 0;
  height: auto;
  margin-left: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(6)}px` : `${theme.getValue(6)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(6)}px` : `${theme.getValue(5)}px`};
`;

const Disclaimer = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(14)}px`
      : `${theme.getFontSize(16)}px`};
  text-align: left;
  width: 100%;
  margin: 0;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
  opacity: 0.85;
`;

const TextContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(16)}px`};
  padding-right: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(16)}px`};
  align-items: flex-start;
  & * {
    color: ${({ color }) => color || "#ffffff"};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(16)}px`};
  padding-right: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(16)}px`};
  align-items: center;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(11)}px` : `${theme.getValue(9)}px`};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(42)}px` : `${theme.getValue(30)}px`};
  padding-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(40)}px`};
`;

const StyledRoundedButtonLink = styled(RoundedButtonLink)`
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(0)}px` : `${theme.getValue(0)}px`};
`;

const Footer = styled.div<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) => backgroundColor || "#447355"};
  width: 100%;
  padding-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(15)}px` : `${theme.getValue(15)}px`};

  min-height: ${({ theme }) =>
    theme.isMobile || isSafari ? "100%" : "-webkit-fill-available"};
`;

const FooterElement = styled.div<{ borderColor?: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(16)}px`};
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding-left: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(21)}px` : `${theme.getValue(21)}px`};
  padding-right: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(9)}px` : `${theme.getValue(9)}px`};
`;

const FooterImage = styled(CheckSVG)`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(13)}px` : `${theme.getValue(13)}px`};
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(13)}px` : `${theme.getValue(13)}px`};
`;

const FooterText = styled(Text)<{ color?: string }>`
  font-weight: 600;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(14)}px`
      : `${theme.getFontSize(20)}px`};
  width: 100%;
  text-align: left;

  &&& {
    color: ${({ color }) => color || "#ffffff"};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const SectionWrapper = styled.div<{ minHeight: number }>`
  min-height: ${({ theme, minHeight }) => (theme.isMobile ? 0 : minHeight)}px;
`;

const CustomSectionWrapper = styled(SectionWrapper)`
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(15)}px` : `${theme.getValue(15)}px`};
  width: 100%;
`;

type ProductProps = {
  analyticsProductData: any;
  backgroundColor: string;
  headerBackgroundColor: string;
  textColor: string;
  headerTextColor: string;
  image: string;
  shortCategoryName: UIText;
  categoryName: UIText;
  productName: UIText;
  description: UIText;
  showPrice: boolean;
  price?: UIText;
  salesPrice?: UIText;
  priceDescription?: UIText;
  priceIconAlt?: string;
  priceIcon?: string;
  disclaimer?: UIText;
  highlights?: Array<UIText>;
  links?: {
    shopNow: string;
    shopNowButtonCopy?: string;
    shopNowButtonBackgroundColor?: string;
    shopNowButtonTextColor?: string;
    shopNowButtonBorderColor?: string;
    learnMore: string;
    learnMoreButtonCopy?: string;
    learnMoreButtonBackgroundColor?: string;
    learnMoreButtonTextColor?: string;
    learnMoreButtonBorderColor?: string;
    seeInAction: string;
    seeInActionButtonCopy?: string;
    seeInActionButtonBackgroundColor?: string;
    seeInActionButtonTextColor?: string;
    seeInActionButtonBorderColor?: string;
  };
  // layout dimensions handlers
  descriptionMinHeight: number;
  pricingMinHeight: number;
  actionsMinHeight: number;
  setActionsMinHeight: (v: number) => void;
  setPricingMinHeight: (v: number) => void;
  setDescriptionMinHeight: (v: number) => void;
};

export const Product = ({
  analyticsProductData,
  backgroundColor,
  headerBackgroundColor,
  textColor,
  headerTextColor,
  image,
  shortCategoryName,
  productName,
  description,
  showPrice,
  price,
  salesPrice,
  priceDescription,
  priceIcon,
  priceIconAlt,
  disclaimer,
  highlights,
  links,
  descriptionMinHeight,
  pricingMinHeight,
  actionsMinHeight,
  setActionsMinHeight,
  setPricingMinHeight,
  setDescriptionMinHeight,
}: ProductProps) => {
  const { isMobile } = useDeviceDetector();
  const labels = useSelector((store: RootState) => store.quiz.labels);
  const partner = useSelector((store: RootState) => store.quiz.partner);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const [descriptionHeight, setDescriptionHeight] = React.useState(0);
  const [pricingHeight, setPricingHeight] = React.useState(0);
  const [actionsHeight, setActionsHeight] = React.useState(0);

  const containerDimensions = useContainerDimensions();

  const getValue = useCallback(
    (value: number): number => {
      return getWidth({
        containerDimensions,
        isMobile,
        value,
      });
    },
    [containerDimensions, isMobile]
  );

  useEffect(() => {
    function handleResize() {
      setDescriptionHeight(descriptionRef.current?.clientHeight || 0);
      setPricingHeight(pricingRef.current?.clientHeight || 0);
      setActionsHeight(actionsRef.current?.clientHeight || 0);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
  });

  useEffect(() => {
    if (descriptionMinHeight < descriptionHeight) {
      setDescriptionMinHeight(Math.ceil(descriptionHeight));
    }
  }, [descriptionHeight, setDescriptionMinHeight, descriptionMinHeight]);

  useEffect(() => {
    if (pricingMinHeight < pricingHeight) {
      setPricingMinHeight(Math.ceil(pricingHeight));
    }
  }, [pricingHeight, setPricingMinHeight, pricingMinHeight]);

  useEffect(() => {
    if (actionsMinHeight < actionsHeight) {
      setActionsMinHeight(Math.ceil(actionsHeight));
    }
  }, [actionsHeight, setActionsMinHeight, actionsMinHeight]);

  const containerHeight =
    pricingMinHeight + actionsMinHeight + descriptionMinHeight + getValue(60);

  return (
    <Container
      id={shortCategoryName?.value + productName?.value}
      backgroundColor={backgroundColor}
    >
      <Header
        backgroundColor={headerBackgroundColor}
        color={headerTextColor}
        text={shortCategoryName}
        tag={"h2"}
      />

      <ImageContainer>
        <ProductImage
          src={image}
          alt={productName?.value}
          responsiveWidth={300}
        />
      </ImageContainer>

      <CustomSectionWrapper minHeight={isMobile ? 0 : containerHeight}>
        <TextContainer color={textColor}>
          <SectionWrapper
            minHeight={isMobile ? 0 : descriptionMinHeight}
            ref={descriptionRef}
          >
            <Title text={productName} tag={"h3"} />
            {/* <Title marginTop={"0"} text={categoryName} tag={"h3"} /> */}
            <Description text={description} tag={"p"} />
          </SectionWrapper>

          <SectionWrapper
            minHeight={isMobile ? 0 : pricingMinHeight}
            ref={pricingRef}
          >
            {showPrice && (
              <>
                {price?.value && salesPrice?.value && (
                  <Pricing>
                    <Price text={salesPrice} tag={"span"} />
                    <OldPrice text={price} tag={"span"} />
                  </Pricing>
                )}
                {price?.value && !salesPrice?.value && (
                  <Pricing>
                    <Price text={price} tag={"span"} />
                  </Pricing>
                )}
                <PriceDescriptionContainer>
                  {priceDescription && (
                    <PriceDescription tag={"p"} text={priceDescription} />
                  )}
                  <PriceImage src={priceIcon} alt={priceIconAlt} />
                </PriceDescriptionContainer>
              </>
            )}
            {disclaimer && <Disclaimer text={disclaimer} tag={"p"} />}
          </SectionWrapper>
        </TextContainer>

        <SectionWrapper
          minHeight={isMobile ? 0 : actionsMinHeight}
          ref={actionsRef}
        >
          {(links?.seeInAction || links?.shopNow || links?.learnMore) && (
            <ButtonsContainer>
              {links?.shopNow && (
                <ButtonWrapper
                  onClick={() => {
                    trackShopLinkClick({
                      productData: analyticsProductData,
                      currencyCode: partner?.currency,
                    });
                  }}
                >
                  <RoundedButtonLink
                    backgroundColor={links?.shopNowButtonBackgroundColor}
                    fontColor={links?.shopNowButtonTextColor}
                    borderColor={links?.shopNowButtonBorderColor}
                    label={{
                      value: links?.shopNowButtonCopy || labels.shopNow,
                    }}
                    link={links?.shopNow}
                    size={"small"}
                  />
                </ButtonWrapper>
              )}
              {links?.learnMore && (
                <RoundedButtonLink
                  backgroundColor={links?.learnMoreButtonBackgroundColor}
                  fontColor={links?.learnMoreButtonTextColor}
                  borderColor={links?.learnMoreButtonBorderColor}
                  label={{
                    value: links?.learnMoreButtonCopy || labels.learnMore,
                  }}
                  link={links?.learnMore}
                  size={"small"}
                />
              )}
              {links?.seeInAction && (
                <StyledRoundedButtonLink
                  backgroundColor={"transparent"}
                  fontColor={links?.seeInActionButtonTextColor}
                  borderColor={links?.seeInActionButtonBorderColor}
                  label={{
                    value: links?.seeInActionButtonCopy || labels.seeInAction,
                  }}
                  link={links?.seeInAction}
                  size={"small"}
                />
              )}
            </ButtonsContainer>
          )}
        </SectionWrapper>
      </CustomSectionWrapper>
      <Footer backgroundColor={headerBackgroundColor}>
        {highlights &&
          highlights.map((h, i) => (
            <FooterElement key={i} borderColor={backgroundColor}>
              <FooterImage color={headerTextColor} />
              <FooterText text={h} tag={"p"} color={headerTextColor} />
            </FooterElement>
          ))}
      </Footer>
    </Container>
  );
};

export default Product;

import React from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { Image } from "../shared/Image";
import RichText from "./RichText";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ theme }) =>
    theme.isMobile ? `center` : "space-between"};
  flex: 1;
  background-color: #447355;
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(1640)}px`};
  flex-wrap: ${({ theme }) => (theme.isMobile ? `wrap` : `nowrap`)};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(950)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(40)}px ${theme.getValue(37)}px`
      : `${theme.getValue(60)}px ${theme.getValue(138)}px`};
  align-items: ${({ theme }) => (theme.isMobile ? `center` : `flex-start`)};
`;

const Header = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: ${({ theme }) => (theme.isMobile ? `center` : `left`)};
  margin: 0;
  margin-left: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(25)}px` : `${theme.getValue(0)}px`};
  margin-right: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(25)}px` : `${theme.getValue(0)}px`};
`;

const StyledImage = styled(Image)`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(375)}px` : `${theme.getValue(800)}px`};
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(170)}px` : "100%"};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(0)}px` : `${theme.getValue(25)}px`};
  object-fit: cover;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(11)}px` : `${theme.getValue(16)}px`};
`;

const Link = styled.a<{ backgroundColor?: string }>`
  text-align: center;
  min-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(166)}px` : `${theme.getValue(260)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(7)}px ${theme.getValue(21)}px`
      : `${theme.getValue(18.5)}px ${theme.getValue(68)}px`};
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(28)}px` : `${theme.getValue(28)}px`};
  text-decoration: none;
  width: fit-content;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
`;

const LinkText = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  text-align: center;
  margin: 0;
`;

type Banner1Props = Banner;

export const Banner1 = ({
  title,
  description,
  button,
  image1,
  image2,
}: Banner1Props) => {
  return (
    <Container>
      <TextContainer>
        <Header text={title} tag={"h2"} />
        <RichText
          fontFamily={description.fontFamily}
          fontWeight={description.fontWeight}
          fontColor={description.fontColor}
          dangerouslySetInnerHTML={{ __html: description.value }}
        />
        {button?.link && (
          <Link
            backgroundColor={button.backgroundColor}
            target={"_blank"}
            href={button?.link}
          >
            <LinkText text={button?.label} tag={"span"} />
          </Link>
        )}
      </TextContainer>
      <ImageContainer>
        {image1 && <StyledImage src={image1?.src} alt={image1?.alt} />}
        {image2 && <StyledImage src={image2?.src} alt={image2?.alt} />}
      </ImageContainer>
    </Container>
  );
};

export default Banner1;

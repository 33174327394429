import React from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ theme }) => (theme.isMobile ? `flex-end` : `center`)};
  align-items: center;
`;

const Title = styled(Text)<{ color: string }>`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(20)}px`
      : `${theme.getFontSize(40)}px`};
  text-align: center;
  padding: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(19)}px` : `${theme.getValue(33)}px`};
  border-bottom: 1px solid #e8eaeb;
  margin: 0;

  &&& {
    color: ${({ color }) => color || "#447355"};
  }
`;

const Content = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  border-top-left-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  border-top-right-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  border-bottom-left-radius: ${({ theme }) =>
    theme.isMobile ? `none` : `${theme.getValue(20)}px`};
  border-bottom-right-radius: ${({ theme }) =>
    theme.isMobile ? `none` : `${theme.getValue(20)}px`};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(40)}px` : `${theme.getValue(71)}px`};
  max-width: ${({ theme }) =>
    theme.isMobile ? `unset` : `${theme.getValue(812)}px`};
`;

const Option = styled.button`
  width: 100%;
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(40)}px` : `${theme.getValue(68)}px`};
  display: flex;
  justify-content: center;
  border: none;
  border-bottom: 1px solid #e8eaeb;
  cursor: pointer;
  background: transparent;
`;

const OptionLabel = styled(Text)<{ color: string }>`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(26)}px`
      : `${theme.getFontSize(36)}px`};
  text-align: center;
  padding: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(7)}px` : `${theme.getValue(18)}px`};
  margin: 0;

  &&& {
    color: ${({ color }) => color || "#000000"};
  }
`;

const OptionContent = styled.div<{ color: string; selected: boolean }>`
  min-width: 40%;
  padding: 0px 16px;
  border-radius: 5px;
  height: 100%;
  background-color: ${({ color, selected }) =>
    selected ? color : "transparent"};
`;

type DropdownProps = {
  title: UIText;
  options: Array<DropdownOption>;
  selectedOption: DropdownOption;
  onClick: (option: DropdownOption) => void;
  onClose: () => void;
  answerModalBackgroundColor: string;
  answerModalSelectedColor: string;
  answerModalSubtitleColor: string;
  answerModalTextColor: string;
};

export const Dropdown = ({
  title,
  options,
  selectedOption,
  onClick,
  onClose,
  answerModalBackgroundColor,
  answerModalSelectedColor,
  answerModalSubtitleColor,
  answerModalTextColor,
}: DropdownProps) => {
  return (
    <Container onClick={onClose}>
      <Content backgroundColor={answerModalBackgroundColor}>
        <Title tag={"p"} color={answerModalSubtitleColor} text={title} />
        {options.map((o) => (
          <Option
            onClick={(e) => {
              e.stopPropagation();
              onClick(o);
            }}
          >
            <OptionContent
              color={answerModalSelectedColor}
              selected={selectedOption.id === o.id}
            >
              <OptionLabel
                color={answerModalTextColor}
                key={o.value}
                text={o.label}
                tag={"p"}
                id={o.value}
              />
            </OptionContent>
          </Option>
        ))}
      </Content>
    </Container>
  );
};

export default Dropdown;

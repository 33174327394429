// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMPTY_UI_BUTTON, EMPTY_UI_TEXT } from "../utils/types";

export interface QuizState {
  home: {
    logoUrl?: string;
    backgroundColor?: string;
    title: UIText;
    description: UIText;
    button: UIButton;
    buttonHoverBackgroundColor?: string;
    bottomAnimation?: {
      desktop: string;
      mobile: string;
    };
  };
  activeQuestions: Array<number>;
  selectedAnswers: { [questionId: string]: Array<BaseAnswer> };
  selectedAnswersProgress: { [questionId: string]: number };
  questions: Array<
    | BaseQuestion
    | SliderQuestion
    | DropdownQuestion
    | IllustrationQuestion
    | MultipleChoiceQuestion
  >;
  partner?: {
    city: string;
    country: string;
    created_at: string;
    currency: string;
    headquarters: string;
    locales: {
      name: string;
      code: string;
    }[];
    name: string;
    state: string;
    time_zone: string;
    updated_at: string;
    uuid: string;
    web_address: string;
    zip_code: string;
  };
  products: Array<Product & Card>;
  loading: LoadingScreen;
  labels: {
    next: string;
    shopNow: string;
    learnMore: string;
    seeInAction: string;
    continue: string;
    emailAddress: string;
    signUp: string;
  };
  results: {
    title: UIText;
    backgroundColor?: string;
    mobileArticlesHeader?: UIText;
    banner1?: Banner;
    banner2?: Banner;
    signUpCopy?: string;
    signUp?: {
      title: UIText;
      description: UIText;
      emailCopy?: string;
      emailSentColor?: string;
      checkboxDefaultValue?: boolean;
      newsletterBackgroundColor?: string;
      showForm?: string;
      signUpCopy?: string;
      legal1?: {
        label: UIText;
        showLink?: boolean;
        link: string;
      };
      legal2?: {
        label: UIText;
        showLink?: boolean;
        link: string;
      };
      promotions?: UIText;
    };
    share?: {
      show?: boolean;
      leftSection: ClickableImageLink;
      rightSection: ClickableImageLink;
    };
  };
}

const initialState: QuizState = {
  home: {
    logoUrl: "",
    title: EMPTY_UI_TEXT,
    description: EMPTY_UI_TEXT,
    button: EMPTY_UI_BUTTON,
  },
  activeQuestions: [],
  selectedAnswers: {},
  selectedAnswersProgress: {},
  questions: [],
  labels: {
    next: "",
    shopNow: "",
    learnMore: "",
    seeInAction: "",
    continue: "",
    emailAddress: "",
    signUp: "",
  },
  loading: {
    title: EMPTY_UI_TEXT,
    subtitle: EMPTY_UI_TEXT,
    description: EMPTY_UI_TEXT,
    animation: "",
    image: "",
    duration: 5,
    notLoadingText: EMPTY_UI_TEXT,
  },
  products: [],
  results: {
    title: {
      value: "",
    },
  },
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizData: (state, action: PayloadAction<QuizState>) => {
      Object.entries(action.payload).forEach(([k, v]) => {
        state[k as keyof QuizState] = v;
      });
    },
    addSelectedAnswers: (
      state,
      action: PayloadAction<{ questionId: string; answers: Array<BaseAnswer> }>
    ) => {
      const { questionId, answers } = action.payload;
      state.selectedAnswers[questionId] = answers;
    },
    addSelectedAnswersProgress: (
      state,
      action: PayloadAction<{ questionId: string; progress: number }>
    ) => {
      const { questionId, progress } = action.payload;
      state.selectedAnswersProgress[questionId] = progress;
    },
    removeSelectedAnswers: (state, action: PayloadAction<string>) => {
      const questionId = action.payload;
      delete state.selectedAnswers[questionId];
    },
    addActiveQuestion: (state, action: PayloadAction<number>) => {
      const activeQuestion = action.payload;
      state.activeQuestions = [...state.activeQuestions, activeQuestion];
    },
    removeActiveQuestion: (state, action: PayloadAction<number>) => {
      const activeQuestion = action.payload;
      state.activeQuestions = state.activeQuestions.filter(
        (q) => q !== activeQuestion
      );
    },
    resetQuizState: (state) => {
      state.activeQuestions = [];
      state.selectedAnswers = {};
    },
  },
});

export const {
  setQuizData,
  addSelectedAnswers,
  addSelectedAnswersProgress,
  removeSelectedAnswers,
  addActiveQuestion,
  removeActiveQuestion,
} = quizSlice.actions;

export default quizSlice.reducer;

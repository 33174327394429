import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { RootState } from "../store";
import { PRODUCT_TYPES_CODE_NAMES } from "../utils/productCategories";

type ProductWithScore = Product & Card & { score?: number };

export const useRecommendedProducts = (): Array<Product & Card> => {
  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const products = useSelector((store: RootState) => store.quiz.products);
  const [recommendedProducts, setRecommendedProducts] = useState<
    Array<ProductWithScore>
  >([]);

  const filterRecommendedProducts = (pList: Array<ProductWithScore>): any => {
    // LOGIC IF BUNDLE OR 2 IN ONE EXISTS
    if (
      pList.some((p) =>
        [
          PRODUCT_TYPES_CODE_NAMES.BUNDLE,
          PRODUCT_TYPES_CODE_NAMES.TWO_IN_ONE_VACUUM_AND_MOP,
        ].includes(p.category.name.value)
      )
    ) {
      const categoriesToCheck = [
        PRODUCT_TYPES_CODE_NAMES.BUNDLE,
        PRODUCT_TYPES_CODE_NAMES.TWO_IN_ONE_VACUUM_AND_MOP,
        PRODUCT_TYPES_CODE_NAMES.ROBOT_MOP,
        PRODUCT_TYPES_CODE_NAMES.ROBOT_VACUUM,
      ];

      const biggerScoreProduct = pList
        .filter((p) => categoriesToCheck.includes(p.category.name.value))
        .sort((a, b) => {
          return (b.score || 0) - (a.score || 0);
        })[0];

      if (
        biggerScoreProduct.category.name.value ===
        PRODUCT_TYPES_CODE_NAMES.BUNDLE
      ) {
        return pList.filter(
          (p) =>
            ![
              PRODUCT_TYPES_CODE_NAMES.TWO_IN_ONE_VACUUM_AND_MOP,
              PRODUCT_TYPES_CODE_NAMES.ROBOT_MOP,
              PRODUCT_TYPES_CODE_NAMES.ROBOT_VACUUM,
            ].includes(p.category.name.value)
        );
      }
      if (
        biggerScoreProduct.category.name.value ===
        PRODUCT_TYPES_CODE_NAMES.TWO_IN_ONE_VACUUM_AND_MOP
      ) {
        return pList.filter(
          (p) =>
            ![
              PRODUCT_TYPES_CODE_NAMES.BUNDLE,
              PRODUCT_TYPES_CODE_NAMES.ROBOT_MOP,
              PRODUCT_TYPES_CODE_NAMES.ROBOT_VACUUM,
            ].includes(p.category.name.value)
        );
      }
      return pList.filter(
        (p) =>
          ![
            PRODUCT_TYPES_CODE_NAMES.BUNDLE,
            PRODUCT_TYPES_CODE_NAMES.TWO_IN_ONE_VACUUM_AND_MOP,
          ].includes(p.category.name.value)
      );
    }
    return pList;
  };

  const getGroupedProducts = useCallback((): {
    [productKind: string]: Array<Product & Card>;
  } => {
    const groupedProducts: { [productKind: string]: Array<Product & Card> } =
      {};
    for (const product of products) {
      const productCategory = product.category.shortName.value;
      if (!groupedProducts[productCategory]) {
        groupedProducts[productCategory] = [];
      }
      groupedProducts[productCategory].push(product);
    }
    return groupedProducts;
  }, [products]);

  const getProductsScores = useCallback((): {
    [productId: string]: number;
  } => {
    const scores: { [productId: string]: number } = {};
    for (const sAnswers of Object.values(selectedAnswers)) {
      const sAnswersScores: { [productId: string]: number } = {};
      sAnswers.forEach((answer: BaseAnswer) => {
        answer.productWeights.forEach(({ productId, weight, disqualify }) => {
          if (typeof sAnswersScores[productId] !== "number") {
            sAnswersScores[productId] = 0;
          }
          if (disqualify) {
            sAnswersScores[productId] = -Infinity;
          }
          sAnswersScores[productId] += weight;
        });
      });
      for (const [productId, score] of Object.entries(sAnswersScores)) {
        if (!scores[productId]) {
          scores[productId] = 0;
        }
        scores[productId] += score / sAnswers.length;
      }
    }
    return scores;
  }, [selectedAnswers]);

  useEffect(() => {
    const productsScores = getProductsScores();
    const groupedProducts = getGroupedProducts();
    const rProducts = [];
    for (const [, gProducts] of Object.entries(groupedProducts)) {
      const productToBeAdded = gProducts
        .filter((p) => productsScores[p.id] > 0)
        .sort((a, b) => {
          return productsScores[b.id] - productsScores[a.id];
        })[0];
      if (productToBeAdded) {
        rProducts.push({
          ...productToBeAdded,
          score: productsScores[productToBeAdded.id],
        });
      }
    }

    setRecommendedProducts(rProducts);
  }, [getGroupedProducts, getProductsScores, setRecommendedProducts]);

  return filterRecommendedProducts(recommendedProducts);
};

export default useRecommendedProducts;

import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "../shared/Text";
import { RootState } from "../../store";
import { Illustration } from "../shared/Illustration";
import { addSelectedAnswers } from "../../store/quiz";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const Header = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: center;
  padding: 0;
  margin: 0;
`;

const SubHeader = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(30)}px`};
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(8)}px` : `${theme.getValue(20)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(15)}px` : `${theme.getValue(10)}px`};
`;

export const MultipleChoiceQuestion = () => {
  const activeQuestions = useSelector(
    (store: RootState) => store.quiz.activeQuestions
  );
  const activeQuestion = useMemo(() => {
    return activeQuestions[activeQuestions.length - 1];
  }, [activeQuestions]);

  const question = useSelector(
    (store: RootState) => store.quiz.questions[activeQuestion || 0]
  ) as MultipleChoiceQuestion;

  const dispatch = useDispatch();

  const handleOptionsChange = useCallback(
    (options: { [key: string]: IllustrationOption }) => {
      dispatch(
        addSelectedAnswers({
          questionId: question?.id,
          answers: Object.values(options),
        })
      );
    },
    [dispatch, question]
  );

  return (
    <Container>
      <Header text={question?.title} tag={"h1"} />
      <SubHeader text={question?.subtitle} tag={"h2"} />
      <Illustration
        illustration={question}
        buttonSelectedTextColor={question.buttonSelectedTextColor}
        buttonSelectedBackgroundColor={question.buttonSelectedBackgroundColor}
        onOptionsChange={handleOptionsChange}
      />
    </Container>
  );
};

export default MultipleChoiceQuestion;

const CREATIVE = {
  DESKTOP: {
    WIDTH: 1920,
    HEIGHT: 934,
  },
  MOBILE: {
    WIDTH: 375,
    HEIGHT: 735,
  },
};

export const getHeight = ({
  containerDimensions,
  isMobile,
  value,
}: {
  containerDimensions: { width: number; height: number };
  isMobile: boolean;
  value: number;
}): number => {
  if (isMobile) {
    return (containerDimensions.height / CREATIVE.MOBILE.HEIGHT) * value;
  }
  return (containerDimensions.height / CREATIVE.DESKTOP.HEIGHT) * value;
};

export const getWidth = ({
  containerDimensions,
  isMobile,
  value,
}: {
  containerDimensions: { width: number; height: number };
  isMobile: boolean;
  value: number;
}): number => {
  if (isMobile) {
    return (containerDimensions.width / CREATIVE.MOBILE.WIDTH) * value;
  }
  return (containerDimensions.width / CREATIVE.DESKTOP.WIDTH) * value;
};

export default {
  getHeight,
  getWidth,
};

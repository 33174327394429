export const PRODUCT_TYPES_CODE_NAMES = {
  BUNDLE: "BUNDLE",
  ROBOT_CODING: "ROBOT_CODING",
  ALL: "ALL",
  ROBOT_VACUUM: "ROBOT_VACUUM",
  ROBOT_MOP: "ROBOT_MOP",
  ROBOT_LAWN_MOWER: "ROBOT_LAWN_MOWER",
  AIR_PURIFIER: "AIR_PURIFIER",
  TWO_IN_ONE_VACUUM_AND_MOP: "TWO_IN_ONE_VACUUM_AND_MOP",
  HANDHELD_VACUUM: "HANDHELD_VACUUM",
};

import { v4 as uuidv4 } from "uuid";
import { FontFamily, FontWeight } from "../types/fonts.d";
import { QuestionType } from "../types/quiz.d";

const createMockedText = (key: string, text: string) => {
  return {
    [key]: text,
    [`${key}FontFamily`]: FontFamily.HarmoniaSansStd,
    [`${key}FontWeight`]: FontWeight.Normal,
  };
};

const createMockedButton = (key: string, text: string) => {
  return {
    [`${key}Label`]: text,
    [`${key}Link`]: `www.google.com/${Math.random()}`,
  };
};

const createMockedWeights = (
  productIds: Array<string>
): Array<{
  productId: string;
  weight: number;
}> => {
  return productIds.map((productId) => ({
    productId,
    weight: Math.random(),
  }));
};

export type MockedQuiz = {
  home: {
    title: string;
    titleFontFamily?: FontFamily;
    titleFontWeight?: FontWeight;
    description: string;
    descriptionFontFamily?: FontFamily;
    descriptionFontWeight?: FontWeight;
    buttonLabel: string;
    buttonLink?: string;
    bottomAnimation?: {
      desktop: string;
      mobile: string;
    };
  };
  questions: Array<{
    id: string;
    title: string;
    titleFontFamily?: FontFamily;
    titleFontWeight?: FontWeight;
    subtitle: string;
    subtitleFontFamily?: FontFamily;
    subtitleFontWeight?: FontWeight;
    type: QuestionType;
    lottieAnimation?: string;
    backgroundColor?: string;
    steps?: Array<{
      id: string;
      title?: string;
      titleFontFamily?: FontFamily;
      titleFontWeight?: FontWeight;
      subtitle?: string;
      subtitleFontFamily?: FontFamily;
      subtitleFontWeight?: FontWeight;
      weights: Array<{
        productId: string;
        weight: number;
      }>;
    }>;
    image?: string;
    optionsTitle?: string;
    optionsTitleFontFamily?: FontFamily;
    optionsTitleFontWeight?: FontWeight;
    options?: Array<{
      id: string;
      title: string;
      titleFontFamily?: FontFamily;
      titleFontWeight?: FontWeight;
      value: string;

      text: string;
      textFontFamily?: FontFamily;
      textFontWeight?: FontWeight;

      activeImage: string;
      nonActiveImage: string;

      image?: string;
      nextQuestionId?: string;
      weights: Array<{
        productId: string;
        weight: number;
      }>;
    }>;
  }>;
  loading: {
    title: string;
    titleFontFamily?: FontFamily;
    titleFontWeight?: FontWeight;
    subtitle: string;
    subtitleFontFamily?: FontFamily;
    subtitleFontWeight?: FontWeight;
    description: string;
    descriptionFontFamily?: FontFamily;
    descriptionFontWeight?: FontWeight;
    notLoadingText: string;
    notLoadingTextFontFamily?: FontFamily;
    notLoadingTextFontWeight?: FontWeight;
    image: string;
    animation: string;
  };
  labels: {
    next: string;
    shopNow: string;
    learnMore: string;
    seeInAction: string;
    continue: string;
    emailAddress: string;
    signUp: string;
  };
  products: Array<{
    id: string;
    name: string;
    description: string;
    price?: string;
    priceDescription?: string;
    priceIcon?: string;
    disclaimer?: string;
    image: string;
    category: {
      name: string;
      shortName: string;
      image: string;
    };
    highlights?: Array<string>;
    headerBackgroundColor: string;
    headerTextColor: string;
    backgroundColor: string;
    textColor: string;
    links: {
      shopNow: string;
      learnMore: string;
      seeInAction: string;
    };
  }>;
  results: {
    title: string;
    titleFontFamily?: FontFamily;
    titleFontWeight?: FontWeight;
    banner1Title: string;
    banner1TitleFontFamily?: FontFamily;
    banner1TitleFontWeight?: FontWeight;
    banner1Description: string;
    banner1DescriptionFontFamily?: FontFamily;
    banner1DescriptionFontWeight?: FontWeight;
    banner1ButtonLabel: string;
    banner1ButtonLink: string;
    banner1Image1Url: string;
    banner1Image1Alt: string;
    banner1Image2Url: string;
    banner1Image2Alt: string;

    banner2Title: string;
    banner2TitleFontFamily?: FontFamily;
    banner2TitleFontWeight?: FontWeight;
    banner2Description: string;
    banner2DescriptionFontFamily?: FontFamily;
    banner2DescriptionFontWeight?: FontWeight;
    banner2ButtonLabel: string;
    banner2ButtonLink: string;
    banner2Image1Url: string;
    banner2Image1Alt: string;

    signUpTitle: string;
    signUpTitleFontFamily?: FontFamily;
    signUpTitleFontWeight?: FontWeight;

    signUpDescription: string;
    signUpDescriptionFontFamily?: FontFamily;
    signUpDescriptionFontWeight?: FontWeight;

    signUpLegal1Label: string;
    signUpLegal1LabelFontFamily?: FontFamily;
    signUpLegal1LabelFontWeight?: FontWeight;
    signUpLegal1Link: string;

    signUpLegal2Label: string;
    signUpLegal2LabelFontFamily?: FontFamily;
    signUpLegal2LabelFontWeight?: FontWeight;
    signUpLegal2Link: string;

    signUpLegalPromotionsLabel: string;
    signUpLegalPromotionsLabelFontFamily?: FontFamily;
    signUpLegalPromotionsLabelFontWeight?: FontWeight;

    leftSectionTitle: string;
    leftSectionTitleFontFamily?: FontFamily;
    leftSectionTitleFontWeight?: FontWeight;
    leftSectionImage: string;
    leftSectionLink: string;
    rightSectionTitle: string;
    rightSectionTitleFontFamily?: FontFamily;
    rightSectionTitleFontWeight?: FontWeight;
    rightSectionImage: string;
    rightSectionLink: string;
  };
};

const LAST_QUESTION_UUID = uuidv4();
const PRODUCTS_IDS = {
  1: uuidv4(),
  2: uuidv4(),
  3: uuidv4(),
  4: uuidv4(),
  5: uuidv4(),
  6: uuidv4(),
  7: uuidv4(),
  8: uuidv4(),
};

export const QUIZ_MOCKED_API_RESPONSE: MockedQuiz = {
  home: {
    ...createMockedText("title", "Find your iRobot dream team."),
    ...createMockedText(
      "description",
      "Your home is as unique as you— and we've got the vacuums, mops, and air purifiers to keep it clean. Take this quiz to find out which products are right for you!"
    ),
    ...createMockedButton("button", "Let's Get Started"),
    bottomAnimation: {
      desktop: "/assets/lottie/robot-wipe-desktop.json",
      mobile: "/assets/lottie/robot-wipe-mobile.json",
    },
  },
  questions: [
    {
      id: uuidv4(),
      ...createMockedText("title", "Let's meet your home!"),
      backgroundColor: "#6A768C",
      type: QuestionType.Illustration,
      image: "/assets/questions/family_off.svg",
      ...createMockedText("subtitle", "Select all that apply"),
      options: [
        {
          id: uuidv4(),
          ...createMockedText("text", "Kids"),
          activeImage: "/assets/questions/family_4_on.svg",
          nonActiveImage: "/assets/questions/family_4_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Adults"),
          activeImage: "/assets/questions/family_1_on.svg",
          nonActiveImage: "/assets/questions/family_1_off.svg",
          nextQuestionId: LAST_QUESTION_UUID,
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Pets"),
          activeImage: "/assets/questions/family_3_on.svg",
          nonActiveImage: "/assets/questions/family_3_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Shedding Pets"),
          activeImage: "/assets/questions/family_2_on.svg",
          nonActiveImage: "/assets/questions/family_2_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
      ],
    },
    {
      id: uuidv4(),
      ...createMockedText(
        "title",
        "Are any of these issues affecting your air quality?"
      ),
      backgroundColor: "#518A70",
      type: QuestionType.MultipleChoice,
      ...createMockedText("subtitle", "Select all that apply"),
      options: [
        {
          id: uuidv4(),
          ...createMockedText(
            "text",
            "Seasonal, pet, or environmental allergies"
          ),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Viruses or airborne illnesses"),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Wildfire Smoke"),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Wildfire Smoke"),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Wildfire Smoke"),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("text", "Wildfire Smoke"),
          activeImage: "/assets/questions/allergies_on.svg",
          nonActiveImage: "/assets/questions/allergies_off.svg",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
      ],
    },
    {
      id: uuidv4(),
      ...createMockedText("title", "What does your space look like?"),
      backgroundColor: "#6A768C",
      type: QuestionType.Slider,
      lottieAnimation: `${process.env.PUBLIC_URL}/assets/lottie/tidy-space.json`,
      steps: [
        {
          id: uuidv4(),
          ...createMockedText("title", "Spotless"),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("title", "Spotless + 1"),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("title", "Lived-In"),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
      ],
    },
    {
      id: uuidv4(),
      ...createMockedText(
        "title",
        "What is your preferred level of cleaning customization?"
      ),
      backgroundColor: "#AE72A3",
      type: QuestionType.Slider,
      lottieAnimation: `${process.env.PUBLIC_URL}/assets/lottie/customization-app.json`,
      steps: [
        {
          id: uuidv4(),
          ...createMockedText("title", "$"),
          ...createMockedText("subtitle", "Just the basics"),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("title", "$$$"),
          ...createMockedText("subtitle", "Total customization"),
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
      ],
    },
    {
      id: LAST_QUESTION_UUID,
      ...createMockedText("title", "Let's meet your home!"),
      backgroundColor: "#6A768C",
      type: QuestionType.Dropwdown,
      lottieAnimation: `${process.env.PUBLIC_URL}/assets/lottie/home-floors.json`,
      ...createMockedText("optionsTitle", "How many levels are in your home?"),
      options: [
        {
          id: uuidv4(),
          ...createMockedText("title", "1"),
          value: "1",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("title", "2"),
          value: "2",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
        {
          id: uuidv4(),
          ...createMockedText("title", "3+"),
          value: "3+",
          weights: createMockedWeights(Object.values(PRODUCTS_IDS)),
        },
      ],
    },
  ],
  loading: {
    animation: `${process.env.PUBLIC_URL}/assets/lottie/loading.json`,
    ...createMockedText(
      "title",
      "Hang tight...\n" + "We're gathering your results!"
    ),
    ...createMockedText("subtitle", "Did you know?"),
    ...createMockedText(
      "description",
      "You can use Alexa or Google Assistant to start, pause, resume, or locate your iRobot vacuum or mop—or even clean by room or zone?"
    ),
    ...createMockedText("notLoadingText", "Your results are ready!"),
    image: `${process.env.PUBLIC_URL}/assets/results/mic.png`,
  },
  labels: {
    next: "Next",
    learnMore: "Learn More",
    seeInAction: "See It in Action",
    shopNow: "Shop It Now",
    continue: "Continue",
    emailAddress: "Email Address",
    signUp: "Sign Up",
  },
  products: [
    {
      id: PRODUCTS_IDS["1"],
      category: {
        name: "Robot Vacuum",
        shortName: "Vacuum",
        image: "/assets/products/roomba.png",
      },
      name: "Roomba® j7+",
      price: "$799.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/j7.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "Perfect for cleaning up after pets!",
        "Empties itself, saving you time!",
        "Great for medium & larger homes!",
      ],
      backgroundColor: "#447f5e",
      headerBackgroundColor: "#276241",
      textColor: "#ffffff",
      headerTextColor: "#ffffff",
      links: {
        shopNow: "/shop",
        seeInAction: "www.google.com",
        learnMore: "https://www.google.com",
      },
    },
    {
      id: PRODUCTS_IDS["2"],
      category: {
        name: "Robot Vacuum",
        shortName: "Vacuum",
        image: "/assets/products/roomba.png",
      },
      name: "Roomba® j7",
      price: "$799.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/j7.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "Perfect for cleaning up after pets!",
        "Empties itself, saving you time!",
        "Great for medium & larger homes!",
      ],
      backgroundColor: "#447f5e",
      headerBackgroundColor: "#276241",
      textColor: "#ffffff",
      headerTextColor: "#ffffff",
      links: {
        shopNow: "/shop",
        seeInAction: "www.google.com",
        learnMore: "https://www.google.com",
      },
    },
    {
      id: PRODUCTS_IDS["3"],
      category: {
        name: "Robot Mop",
        shortName: "Mop",
        image: "/assets/products/jet_m6.png",
      },
      name: "Braava jet® m6",
      price: "$499.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/j7.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "Precision Jet Spray helps tackle sticky messes and kitchen grease!",
        "Ideal for multiple rooms and larger spaces!",
        "Learns from your cleaning habits to offer customized schedules!",
      ],
      backgroundColor: "#87A28E",
      headerBackgroundColor: "#5E7A63",
      textColor: "#000000",
      headerTextColor: "#ffffff",
      links: {
        shopNow: "/shop",
        seeInAction: "www.google.com",
        learnMore: "https://www.google.com",
      },
    },
    {
      id: PRODUCTS_IDS["4"],
      category: {
        name: "Robot Mop",
        shortName: "Mop",
        image: "/assets/products/jet_m6.png",
      },
      name: "Braava jet® m6 +",
      price: "$499.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/j7.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "Precision Jet Spray helps tackle sticky messes and kitchen grease!",
        "Ideal for multiple rooms and larger spaces!",
        "Learns from your cleaning habits to offer customized schedules!",
      ],
      backgroundColor: "#87A28E",
      headerBackgroundColor: "#5E7A63",
      textColor: "#000000",
      headerTextColor: "#ffffff",
      links: {
        shopNow: "/shop",
        seeInAction: "www.google.com",
        learnMore: "https://www.google.com",
      },
    },
    {
      id: PRODUCTS_IDS["5"],
      category: {
        name: "iRobot aair Lite",
        shortName: "Air Purifier",
        image: "/assets/products/jet_m6.png",
      },
      name: "Klaara® A7",
      price: "$399.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/jet_m6.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "For bedrooms, offices, and other indoor spaces!",
        "For rooms 700 sq. ft. & below!",
        "10-month average filter life!",
      ],
      backgroundColor: "#6A768C",
      headerBackgroundColor: "#48586B",
      textColor: "#ffffff",
      headerTextColor: "#ffffff",
    },
    {
      id: PRODUCTS_IDS["6"],
      category: {
        name: "iRobot aair Lite",
        shortName: "Air Purifier",
        image: "/assets/products/jet_m6.png",
      },
      name: "Klaara® A7+",
      price: "$399.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/jet_m6.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "For bedrooms, offices, and other indoor spaces!",
        "For rooms 700 sq. ft. & below!",
        "10-month average filter life!",
      ],
      backgroundColor: "#6A768C",
      headerBackgroundColor: "#48586B",
      textColor: "#ffffff",
      headerTextColor: "#ffffff",
    },
    {
      id: PRODUCTS_IDS["7"],
      category: {
        name: "Handheld Vacuum",
        shortName: "Handheld Vacuum",
        image: "/assets/products/jet_m6.png",
      },
      name: "iRobot® H1 2",
      price: "$399.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/jet_m6.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "For bedrooms, offices, and other indoor spaces!",
        "For rooms 700 sq. ft. & below!",
        "10-month average filter life!",
      ],
      backgroundColor: "#AE72A3",
      headerBackgroundColor: "#965A8E",
      textColor: "#000000",
      headerTextColor: "#000000",
    },
    {
      id: PRODUCTS_IDS["8"],
      category: {
        name: "Handheld Vacuum",
        shortName: "Handheld Vacuum",
        image: "/assets/products/jet_m6.png",
      },
      name: "iRobot® H1",
      price: "$399.99",
      description:
        "Forget about vacuuming for months at a time with the Clean Base® Automatic Dirt Disposal that allows the Roomba® j7+ robot vacuum to empty itself for up to 60 days.",
      image: "/assets/products/jet_m6.png",
      priceDescription: "Or, as low as $73/mo. with",
      priceIcon: "/assets/products/affirm.svg",
      disclaimer: "Also available without the Clean Base®",
      highlights: [
        "For bedrooms, offices, and other indoor spaces!",
        "For rooms 700 sq. ft. & below!",
        "10-month average filter life!",
      ],
      backgroundColor: "#AE72A3",
      headerBackgroundColor: "#965A8E",
      textColor: "#000000",
      headerTextColor: "#000000",
    },
  ],
  results: {
    ...createMockedText(
      "title",
      "We've got the perfect lineup for your whole home clean!"
    ),

    ...createMockedText("banner1Title", "Scheduling at its smartest!"),
    ...createMockedText(
      "banner1Description",
      "Don't spend precious time in your day worrying about cleaning schedules–the Roomba® robot vacuum can do that. It uses iRobot Genius to learn the times you like to clean and suggests automated schedules to accommodate."
    ),
    banner1ButtonLabel: "LEARN ABOUT OUR GENIUS FEATURES",
    banner1ButtonLink: "www.irobot.com",
    banner1Image1Url: `${process.env.PUBLIC_URL}/assets/results/banner1_1.png`,
    banner1Image1Alt: "banner 1",
    banner1Image2Url: `${process.env.PUBLIC_URL}/assets/results/banner1_2.png`,
    banner1Image2Alt: "banner 2",

    ...createMockedText(
      "banner2Title",
      "A cleaning robot for your floors. A membership by your side."
    ),
    ...createMockedText(
      "banner2Description",
      "When you choose iRobot Select for $29 a month*, you don't just get the Roomba® j7+ robot vacuum with the most advanced technology— you also get a dedicated support team, accessories automatically shipped as you need them, and a premium protection plan."
    ),
    banner2ButtonLabel: "LEARN MORE",
    banner2ButtonLink: "www.irobot.com",
    banner2Image1Url: `${process.env.PUBLIC_URL}/assets/results/banner2_1.png`,
    banner2Image1Alt: "banner 2",

    ...createMockedText("signUpTitle", "Sign up to receive updates"),
    ...createMockedText(
      "signUpDescription",
      "We'll send you this plan and keep in touch about other exciting iRobot news that will revolutionize the way you keep your home clean."
    ),
    ...createMockedText(
      "signUpLegal1Label",
      "By signing up, I agree to the Privacy Policy and the Terms & Conditions"
    ),
    signUpLegal1Link: "www.google.com",
    ...createMockedText(
      "signUpLegal2Label",
      "By signing up, I agree to the Privacy Policy and the Terms & Conditions"
    ),
    signUpLegal2Link: "www.google.com",

    ...createMockedText("rightSectionTitle", "Send me my results"),
    rightSectionImage: "/assets/results/email_2.svg",
    rightSectionLink: "www.google.com",

    ...createMockedText("leftSectionTitle", "Send me my results"),
    leftSectionImage: "/assets/results/email.svg",
    leftSectionLink: "www.google.com",

    ...createMockedText(
      "signUpLegalPromotionsLabel",
      "Opt-in to receive promotions. "
    ),
  },
} as MockedQuiz;

export default QUIZ_MOCKED_API_RESPONSE;

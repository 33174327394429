import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BaseScreen } from "../components/shared/BaseScreen";
import { Text } from "../components/shared/Text";
import { ProductCategory } from "../components/results/ProductCategory";
import { Product } from "../components/results/Product";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { trackQuizResults } from "../utils/analytics";
import { Banner1 } from "../components/results/Banner1";
import { Banner2 } from "../components/results/Banner2";
import { SignUp } from "../components/results/SignUp";
import { Share } from "../components/results/Share";
import { useRecommendedProducts } from "../hooks/useRecommendedProducts";
import { useDeviceDetector } from "../hooks/useDeviceDetector";

const Container = styled(BaseScreen)<{
  backgroundColor?: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor || "#DCE9D5"};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileHeader = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.getFontSize(26)}px;
  text-align: center;
  margin: 0;
  width: 100%;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(0)}px` : `${theme.getValue(85)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(27)}px` : `${theme.getValue(35)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(0)}px ${theme.getValue(37)}px`
      : `${theme.getValue(0)}px ${theme.getValue(554)}px`};
`;

const Title = styled(Text)<{ color?: string }>`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  text-align: center;
  margin: 0;
  width: 100%;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(27)}px` : `${theme.getValue(85)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(27)}px` : `${theme.getValue(35)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(0)}px ${theme.getValue(37)}px`
      : `${theme.getValue(0)}px ${theme.getValue(554)}px`};

  &&& {
    color: ${({ color }) => color || "#447355"};
  }
`;

const ProductCategoryContainer = styled.div`
  display: flex;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(302)}px` : `${theme.getValue(302)}px`};
  flex-wrap: wrap;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? `column` : `row`)};
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(16)}px`};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(27)}px` : `${theme.getValue(35)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(57)}px` : `${theme.getValue(20)}px`};
  flex-wrap: wrap;
  max-width: ${({ theme }) =>
    theme.isMobile ? `unset` : `${theme.getValue(1640)}px`};
`;

const Content = styled.div`
  animation: fadeIn 5s;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const parseProductData = (product: any) => ({
  sku: product.sku || "",
  productData: {
    name: product.name.value || "",
    category: product.category.name.value || "",
    productType: product.category.name.value || "",
  },
  productUuid: product.id,
});

export const Results = () => {
  const { isMobile } = useDeviceDetector();
  const recommendedProducts = useRecommendedProducts();
  const resultsData = useSelector((store: RootState) => store.quiz.results);

  const [descriptionMinHeight, setDescriptionMinHeight] = useState(0);
  const [pricingMinHeight, setPricingMinHeight] = useState(0);
  const [actionsMinHeight, setActionsMinHeight] = useState(0);

  useEffect(() => {
    if (!!recommendedProducts.length) {
      trackQuizResults(
        recommendedProducts.map((product) => parseProductData(product))
      );
    }
  }, [recommendedProducts]);

  return (
    <Container backgroundColor={resultsData.backgroundColor}>
      <Content>
        <Title
          tag={"h1"}
          color={resultsData?.title.fontColor}
          text={resultsData?.title}
        />
        {isMobile && (
          <ProductCategoryContainer>
            {recommendedProducts &&
              recommendedProducts.map((product) => (
                <ProductCategory
                  key={product?.name?.value}
                  text={product?.headerText || product?.category?.shortName}
                  headerBackgroundColor={product?.headerBackgroundColor}
                  backgroundColor={product?.backgroundColor}
                  headerTextColor={product?.headerTextColor}
                  image={product?.image || ""}
                  link={`#${
                    (product?.headerText?.value ||
                      product?.category?.shortName.value) + product?.name?.value
                  }`}
                />
              ))}
          </ProductCategoryContainer>
        )}
        <ProductsContainer>
          {recommendedProducts &&
            recommendedProducts?.map((product) => (
              <Product
                analyticsProductData={parseProductData(product)}
                key={product?.name?.value}
                productName={product?.name}
                description={product?.description}
                price={product?.price}
                salesPrice={product?.salesPrice}
                image={product?.image}
                backgroundColor={product?.backgroundColor}
                headerBackgroundColor={product?.headerBackgroundColor}
                textColor={product?.textColor}
                headerTextColor={product?.headerTextColor}
                categoryName={product?.category?.shortName}
                shortCategoryName={
                  product?.headerText || product?.category?.shortName
                }
                showPrice={product?.showPrice}
                priceDescription={product?.priceDescription}
                priceIconAlt={product?.priceIconAlt}
                priceIcon={product?.priceIcon}
                disclaimer={product?.disclaimer}
                highlights={product?.highlights}
                links={product?.links}
                descriptionMinHeight={descriptionMinHeight}
                pricingMinHeight={pricingMinHeight}
                actionsMinHeight={actionsMinHeight}
                setActionsMinHeight={setActionsMinHeight}
                setPricingMinHeight={setPricingMinHeight}
                setDescriptionMinHeight={setDescriptionMinHeight}
              />
            ))}
        </ProductsContainer>
        {resultsData?.share && resultsData?.share?.show && (
          <Share
            leftSectionImage={resultsData.share.leftSection?.image?.src}
            leftSectionLink={resultsData.share.leftSection?.link}
            leftSectionTitle={resultsData.share.leftSection?.label}
            rightSectionImage={resultsData.share.rightSection?.image?.src}
            rightSectionLink={resultsData.share.rightSection?.link}
            rightSectionTitle={resultsData.share.rightSection?.label}
          />
        )}
        {resultsData?.signUp && resultsData?.signUp.showForm && (
          <SignUp
            title={resultsData?.signUp?.title}
            description={resultsData?.signUp?.description}
            emailCopy={resultsData?.signUp?.emailCopy}
            emailSentColor={resultsData?.signUp?.emailSentColor}
            checkboxDefaultValue={resultsData?.signUp?.checkboxDefaultValue}
            newsletterBackgroundColor={
              resultsData?.signUp?.newsletterBackgroundColor
            }
            signUpCopy={resultsData?.signUp?.signUpCopy}
            legal1={resultsData?.signUp?.legal1}
            legal2={resultsData?.signUp?.legal2}
            promotions={resultsData?.signUp?.promotions}
          />
        )}
        {isMobile && resultsData?.mobileArticlesHeader && (
          <MobileHeader tag={"h2"} text={resultsData?.mobileArticlesHeader} />
        )}
        {resultsData?.banner1 && <Banner1 {...resultsData?.banner1} />}
        {resultsData?.banner2 && <Banner2 {...resultsData?.banner2} />}
      </Content>
    </Container>
  );
};

export default Results;

import * as ga from "./ga";
import * as pixel from "./pixel";

let selectedAnswers: any = [];

export function trackSelectedAnswer(
  currentAnswer = {},
  auxSelectedAnswers = []
) {
  selectedAnswers = auxSelectedAnswers;
  ga.onSelectAnswer((window as any).dataLayer, currentAnswer, selectedAnswers);
  pixel.onSelectAnswer(
    (window as any).genomeTP,
    currentAnswer,
    selectedAnswers
  );
}

// pops from the n element of the selectedAnswers array
export function trackBacktracking(steps = 1) {
  // ex: array.length = 5 [0..4]
  // nIDX = 5 - 1 =  4
  // mutate the array and delete from the nIDX 4 n amounts of steps
  const nIDX = selectedAnswers.length - steps;

  selectedAnswers.splice(nIDX, steps);
}

export function trackQuizResults(resultsProducts: any) {
  ga.onLoadResults((window as any).dataLayer, resultsProducts);
  pixel.onLoadResults(
    (window as any).genomeTP,
    resultsProducts,
    selectedAnswers
  );
}

export function trackShopLinkClick(trackingProductData: any) {
  ga.onProductClick((window as any).dataLayer, trackingProductData);
  pixel.onClickProduct((window as any).genomeTP, trackingProductData);
}

export function trackOtherRecommendationsImpressions(otherProductsData: any) {
  ga.onOtherRecommendationsVisible(
    (window as any).dataLayer,
    otherProductsData
  );
}

export function trackSubmitEmailEvent(sourceCta = "") {
  ga.onSubmitEmail((window as any).dataLayer, sourceCta);
  pixel.onEmailSubmit((window as any).genomeTP, sourceCta);
}

export function trackQuizLoad(metadata: any) {
  ga.onQuizLoad((window as any).dataLayer, metadata);
}

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Text } from "../shared/Text";
import { useSelector } from "react-redux";
import { trackSubmitEmailEvent } from "../../utils/analytics";
import { RootState } from "../../store";
import { saveResults } from "../../services/api";
import IconCheck from "../IconCheck";
import { useDeviceDetector } from "../../hooks/useDeviceDetector";

const Container = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor || "#447355"};
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(1640)}px`};
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(40)}px ${theme.getValue(37)}px`
      : `${theme.getValue(40)}px ${theme.getValue(276)}px`};
  margin-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(20)}px`};
`;

const Header = styled(Text)`
  text-align: center;
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(812)}px`};
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(22)}px`
      : `${theme.getFontSize(48)}px`};
  margin: 0;
`;

const Description = styled(Text)`
  width: ${({ theme }) =>
    theme.isMobile ? `100%` : `${theme.getValue(536)}px`};
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(20)}px`};
  text-align: center;
  margin: 0;
`;

const Legal = styled.a<{ color?: string }>`
  text-decoration-color: ${({ color }) => color || "#87a28e"};
  text-align: ${({ theme }) => (theme.isMobile ? `center` : `left`)};
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(8)}px` : `${theme.getValue(0)}px`};
`;

const LegalText = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(14)}px`
      : `${theme.getFontSize(20)}px`};
  text-align: ${({ theme }) => (theme.isMobile ? `center` : `left`)};
  margin: 0;
`;

const Email = styled.input<{
  backgroundColor?: string;
  submitting?: boolean;
  submitted?: boolean;
}>`
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(11)}px ${theme.getValue(12)}px`
      : `${theme.getValue(11)}px ${theme.getValue(12)}px`};
  border: 0;
  width: ${({ theme, submitted }) => {
    const getWidth = (): string => {
      if (submitted) {
        return theme.isMobile
          ? `${theme.getValue(302)}px`
          : `${theme.getValue(586)}px`;
      }
      return theme.isMobile
        ? `${theme.getValue(302)}px`
        : `${theme.getValue(536)}px`;
    };

    return getWidth();
  }};
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  color: #6a768c;
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(11)}px` : `${theme.getValue(11)}px`};
  background-color: ${({ backgroundColor }) => backgroundColor || "#dee2e6"};
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(14)}px` : `${theme.getValue(14)}px`};
`;

const LegalContainer = styled.div`
  width: 100%;
`;

const Button = styled.button<{ disabled?: boolean }>`
  max-width: 100%;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(7)}px ${theme.getValue(56)}px`
      : `${theme.getValue(18.5)}px ${theme.getValue(61)}px`};
  background-color: transparent;
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(28)}px` : `${theme.getValue(31)}px`};
  border: 2px solid #ffffff;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ButtonText = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  text-align: ${({ theme }) => (theme.isMobile ? `center` : `center`)};
  margin: 0;

  &&& {
    color: #ffffff;
  }
`;

const SuccessIcon = styled.div`
  border: 1px solid white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(14)}px`
      : `${theme.getValue(7)}px ${theme.getValue(14)}px`};
`;

const LegalLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type SignUpProps = {
  title: UIText;
  description: UIText;
  emailCopy?: string;
  emailSentColor?: string;
  checkboxDefaultValue?: boolean;
  newsletterBackgroundColor?: string;
  signUpCopy?: string;
  legal1?: {
    label: UIText;
    showLink?: boolean;
    link: string;
  };
  legal2?: {
    label: UIText;
    showLink?: boolean;
    link: string;
  };
  promotions?: UIText;
};

export const SignUp = ({
  title,
  description,
  emailCopy,
  signUpCopy,
  newsletterBackgroundColor,
  emailSentColor,
  legal1,
  legal2,
}: SignUpProps) => {
  const { isMobile } = useDeviceDetector();
  const labels = useSelector((store: RootState) => store.quiz.labels);
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e?.target?.value?.toLowerCase());
    },
    [setEmail]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await saveResults({ contact_email: email });
      trackSubmitEmailEvent(labels?.signUp);
      setSubmitted(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }, [email, labels]);

  React.useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setSubmitted(false);
        setEmail("");
      }, 4500);
    }
  }, [submitted]);

  return (
    <Container backgroundColor={newsletterBackgroundColor}>
      <Header text={title} tag={"h2"} />
      <Description text={description} tag={"p"} />
      <EmailContainer>
        <Email
          disabled={submitting || submitted}
          backgroundColor={email.length ? emailSentColor : undefined}
          submitting={submitting}
          submitted={submitted}
          value={email}
          placeholder={emailCopy || labels?.emailAddress}
          onChange={handleChange}
        />
        {!isMobile && (
          <>
            {submitted ? (
              <SuccessIcon>
                <IconCheck className="success--icon" />
              </SuccessIcon>
            ) : (
              <Button disabled={submitting}>
                <ButtonText
                  text={{ value: signUpCopy || labels?.signUp }}
                  onClick={handleSubmit}
                />
              </Button>
            )}
          </>
        )}
      </EmailContainer>

      <LegalContainer>
        {!isMobile && (
          <LegalLinks>
            {legal1?.showLink && legal1?.link && (
              <Legal
                color={description?.fontColor}
                target={"_blank"}
                href={legal1?.link}
              >
                <LegalText text={legal1?.label} tag={"span"} />
              </Legal>
            )}
            {legal2?.showLink && legal2?.link && (
              <Legal
                color={description?.fontColor}
                target={"_blank"}
                href={legal2?.link}
              >
                <LegalText text={legal2?.label} tag={"span"} />
              </Legal>
            )}
          </LegalLinks>
        )}
      </LegalContainer>
      {isMobile && (
        <>
          {submitted ? (
            <SuccessIcon>
              <IconCheck className="success--icon" />
            </SuccessIcon>
          ) : (
            <Button disabled={submitting}>
              <ButtonText
                text={{ value: signUpCopy || labels?.signUp }}
                onClick={handleSubmit}
              />
            </Button>
          )}

          <LegalLinks>
            {legal1?.showLink && legal1?.link && (
              <Legal
                color={description?.fontColor}
                target={"_blank"}
                href={legal1?.link}
              >
                <LegalText text={legal1?.label} tag={"span"} />
              </Legal>
            )}
            {legal2?.showLink && legal2?.link && (
              <Legal
                color={description?.fontColor}
                target={"_blank"}
                href={legal2?.link}
              >
                <LegalText text={legal2?.label} tag={"span"} />
              </Legal>
            )}
          </LegalLinks>
        </>
      )}
    </Container>
  );
};

export default SignUp;

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.011}
    height={16.769}
    {...props}
  >
    <path
      d="M7.95 1.061.75 8.389l7.191 7.319"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgComponent;

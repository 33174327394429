import React from "react";
import styled from "styled-components";
import { FontFamily, FontWeight } from "../../types/fonts.d";

const StyledText = styled.p<{
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;
  fontColor?: string;
}>`
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};

  &&& {
    line-height: 1.1;
    color: ${({ fontColor }) => fontColor};
  }
`;

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  className?: string;
  tag?: string;
  text: UIText;
  htmlFor?: string;
}

export const Text = ({ id, className, text, tag, ...props }: TextProps) => {
  if (!text || !text.value) {
    return null;
  }
  const arrayValue = text?.value.split("\\n");
  return (
    <StyledText
      id={id}
      as={tag as never}
      className={className}
      fontFamily={text?.fontFamily || FontFamily.HarmoniaSansStd}
      fontWeight={text?.fontWeight || FontWeight.Normal}
      fontColor={text?.fontColor || "#ffffff"}
      {...props}
    >
      {arrayValue && arrayValue.length > 1
        ? arrayValue.map((line, i) => <span key={i}>{line}</span>)
        : text?.value}
    </StyledText>
  );
};

export default Text;

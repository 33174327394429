// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Route } from "../types/routes.d";

export interface RouterState {
  activeScreen: Route;
}

const initialState: RouterState = {
  activeScreen: Route.Question,
};

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    setActiveScreen: (state, action: PayloadAction<Route>) => {
      const route = action.payload;
      state.activeScreen = route;
    },
  },
});

export const { setActiveScreen } = routerSlice.actions;

export default routerSlice.reducer;

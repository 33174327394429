import styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Text } from "./Text";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDeviceDetector } from "../../hooks/useDeviceDetector";

const Content = styled.div`
  position: relative;
  width: ${({ theme }) =>
    theme.isMobile ? "100%" : `${theme.getValue(398)}px`};
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(320)}px` : `${theme.getValue(507)}px`};
  margin: auto;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(16)}px` : `${theme.getValue(70)}px`};
`;

const Image = styled.img<{ active: boolean }>`
  width: 100%;
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(300)}px` : `${theme.getValue(473)}px`};
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s linear;
  pointer-events: none;
`;

const Button = styled(Text)<{
  active: boolean;
  left: number;
  top: number;
  buttonSelectedTextColor: string;
  buttonSelectedBackgroundColor: string;
}>`
  background-color: ${({ active, buttonSelectedBackgroundColor }) =>
    active ? buttonSelectedBackgroundColor || "#FFFFFF" : "transparent"};
  font-size: ${({ theme }) =>
    theme.isMobile ? `${theme.getFontSize(16)}px` : `${theme.getValue(20)}px`};
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(14)}px` : `${theme.getValue(14)}px`};
  font-weight: 700;
  letter-spacing: 0.1em;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(4)}px ${theme.getValue(10)}px`
      : `${theme.getValue(2)}px ${theme.getValue(10)}px`};
  position: absolute;
  border: 1.5px solid #ffffff;
  top: ${({ top, theme }) => theme.getValue(top)}px;
  left: ${({ left, theme }) => theme.getValue(left)}px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;

  &&& {
    color: ${({ active, buttonSelectedTextColor }) =>
      active ? buttonSelectedTextColor || "#bd7bae" : "#FFFFFF"};
  }
`;

const BUTTONS_POSITIONS = {
  DESKTOP: {
    "1": {
      top: 117,
      left: 0,
    },
    "2": {
      top: 9,
      left: 220,
    },
    "3": {
      top: 142,
      left: 335,
    },
    "4": {
      top: 482,
      left: 173,
    },
  },
  MOBILE: {
    "1": {
      top: 70,
      left: 0,
    },
    "2": {
      top: 4,
      left: 180,
    },
    "3": {
      top: 90,
      left: 250,
    },
    "4": {
      top: 300,
      left: 146,
    },
  },
};

type IllustrationProps = {
  illustration: MultipleChoiceQuestion;
  buttonSelectedTextColor: string;
  buttonSelectedBackgroundColor: string;
  onOptionsChange: (selectedOptions: {
    [key: string]: IllustrationOption;
  }) => void;
};

export const Illustration = ({
  illustration,
  onOptionsChange,
  buttonSelectedBackgroundColor,
  buttonSelectedTextColor,
}: IllustrationProps) => {
  const { isMobile } = useDeviceDetector();
  const [activeOptions, setActiveOptions] = useState<{
    [key: string]: IllustrationOption;
  }>({});
  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const { options } = illustration;

  const selectedOptions = useMemo(() => {
    const auxOptions: any = {};
    (selectedAnswers[illustration.id] || []).map((answer) => {
      auxOptions[answer.id] = answer;
    });
    return auxOptions;
  }, [illustration, selectedAnswers]);

  const getButtonPosition = useCallback(
    (index: "1" | "2" | "3" | "4"): { top: number; left: number } | null => {
      const positionKey = isMobile ? "MOBILE" : "DESKTOP";
      if (
        BUTTONS_POSITIONS[positionKey] &&
        BUTTONS_POSITIONS[positionKey][index]
      ) {
        return BUTTONS_POSITIONS[positionKey][index];
      }
      return null;
    },
    [isMobile]
  );

  const handleOptionClick = useCallback(
    (o: IllustrationOption) => {
      const copyActiveOptions = { ...activeOptions };
      if (copyActiveOptions[o.id]) {
        delete copyActiveOptions[o.id];
      } else {
        copyActiveOptions[o.id] = o;
      }
      setActiveOptions(copyActiveOptions);
      onOptionsChange(copyActiveOptions);
    },
    [activeOptions, setActiveOptions, onOptionsChange]
  );

  useEffect(() => {
    setActiveOptions(selectedOptions);
  }, [selectedOptions]);

  return (
    <Content>
      {options
        .filter((o, i) => i <= 3)
        .map((o, i) => {
          const buttonPosition = getButtonPosition(
            (i + 1 + "") as "1" | "2" | "3" | "4"
          );
          return (
            <React.Fragment key={o.id}>
              <Image
                active={!!activeOptions[o.id]}
                src={o.activeImage}
                alt={o.text?.value}
              />
              <Image
                active={!activeOptions[o.id]}
                src={o.nonActiveImage as string}
                alt={o.text?.value}
              />
              <Button
                text={o.text}
                tag={"button"}
                active={!!activeOptions[o.id]}
                buttonSelectedBackgroundColor={buttonSelectedBackgroundColor}
                buttonSelectedTextColor={buttonSelectedTextColor}
                left={buttonPosition?.left || 0}
                top={buttonPosition?.top || 0}
                onClick={() => handleOptionClick(o)}
              />
            </React.Fragment>
          );
        })}
    </Content>
  );
};

export enum FontFamily {
  HarmoniaSansStd = "HarmoniaSansStd",
  HarmoniaSansPro = "HarmoniaSansPro",
  "HarmoniaSans-Condensed" = "HarmoniaSans-Condensed",
  "Myriad Pro" = "Myriad Pro",
}

export enum FontWeight {
  Light = "300",
  Normal = "400",
  Medium = "500",
  SemiBold = "600",
  Bold = "700",
  ExtraBold = "800",
  Black = "900",
}

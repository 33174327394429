import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";

const StyledSlider = styled(ReactSlider)`
  background-color: transparent;
  opacity: 1;
  width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(302) : theme.getValue(522)}px;
  height: ${({ theme }) =>
    theme.isMobile ? theme.getValue(25.3) : theme.getValue(43.81)}px;
  display: flex;
  align-items: center;
`;

const StyledThumb = styled.div`
  opacity: 1;
  height: ${({ theme }) =>
    theme.isMobile ? theme.getValue(25.3) : theme.getValue(44)}px;
  width: ${({ theme }) =>
    theme.isMobile ? theme.getValue(25.3) : theme.getValue(44)}px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: #000000 1px 1px;
  cursor: grab;
`;

const Thumb = (props: any) => <StyledThumb {...props} />;

const StyledTrack = styled.div<{ index?: number }>`
  background-color: #ffffff;
  opacity: ${(props) => (props.index === 1 ? 0.25 : 1)};
  height: ${({ theme }) =>
    theme.isMobile ? theme.getValue(11.19) : theme.getValue(19.38)}px;
  border-radius: ${({ theme }) =>
    theme.isMobile ? theme.getValue(5.36) : theme.getValue(10)}px;
`;

const Track = (props: any, state: { index: number }) => (
  <StyledTrack {...props} index={state.index} />
);

const Container = styled.div<{ accentColor: string }>`
  width: 100%;

  .track-0,
  .thumb-0,
  .track-1 {
    background-color: ${({ accentColor }) => accentColor};
  }
`;

type SliderProps = {
  className?: string;
  children?: React.ReactNode;
  accentColor?: string;
  min?: number;
  value?: number;
  max?: number;
  step?: number;
  onChange: (value: number | readonly number[], index: number) => void;
};

export const Slider = ({
  className,
  accentColor = "#ffffff",
  min = 0,
  max = 100,
  step = 1,
  value = 0,
  onChange,
}: SliderProps) => {
  return (
    <Container accentColor={accentColor} className={className}>
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={step}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={onChange}
      />
    </Container>
  );
};

export default Slider;

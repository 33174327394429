import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { trackQuizLoad } from "../utils/analytics";
import { RootState } from "../store";
import { RoundedButton } from "../components/shared/RoundedButton";
import { Image } from "../components/shared/Image";
import { Text } from "../components/shared/Text";
import { Lottie } from "../components/shared/Lottie";
import { useContainerDimensions } from "../hooks/useContainerDimensions";
import { getWidth } from "../utils/ui";
import { Route } from "../types/routes.d";
import { addActiveQuestion } from "../store/quiz";
import { useDeviceDetector } from "../hooks/useDeviceDetector";

const Container = styled.section<{ backgroundColor?: string }>`
  background: ${({ backgroundColor = "#518a70" }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getValue(108)}px ${theme.getValue(37)}px ${theme.getValue(
          226
        )}px`
      : `${theme.getValue(144)}px ${theme.getValue(60)}px ${theme.getValue(
          285
        )}px`};
  gap: ${({ theme }) => `${theme.getValue(20)}px`};
  position: relative;
  height: 100%;
`;

const StyledImage = styled(Image)`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(54)}px` : `${theme.getValue(90)}px`};
  height: auto;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(30)}px`
      : `${theme.getFontSize(48)}px`};
  max-width: ${({ theme }) => `${theme.getValue(1000)}px`};
  margin: 0;
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(18)}px`
      : `${theme.getFontSize(24)}px`};
  max-width: ${({ theme }) => `${theme.getValue(600)}px`};
  margin: ${({ theme }) => `${theme.getValue(3)}px 0`};
`;

const LottieWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;

  &:after {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${({ theme }) =>
      theme.isMobile ? `${theme.getValue(99)}px` : `${theme.getValue(84)}px`};
    content: "";
    background: #dce9d5;
  }
`;

const StyledLottie = styled(Lottie)`
  overflow: hidden;
`;

export const Home = () => {
  const { isMobile } = useDeviceDetector();
  const [playing, setPlaying] = useState<boolean>(false);
  const [rerenderLottie, setRerenderLottie] = useState(1);
  const dispatch = useDispatch();
  const containerDimensions = useContainerDimensions();
  const quizHome = useSelector((store: RootState) => store.quiz.home);
  const partner = useSelector((store: RootState) => store.quiz.partner);
  const navigate = useNavigate();

  const haveLottie = useMemo(
    () =>
      !!(isMobile
        ? quizHome?.bottomAnimation?.mobile
        : quizHome?.bottomAnimation?.desktop),
    [isMobile, quizHome]
  );

  const onComplete = useCallback(() => {
    dispatch(addActiveQuestion(0));
    navigate(`${Route.Question}/0`);
  }, [dispatch, navigate]);

  const handleButtonClick = useCallback(() => {
    setPlaying(true);
    if (!haveLottie) onComplete();
  }, [onComplete, haveLottie]);

  useEffect(() => {
    const metadata = {
      partnerName: partner?.name || "",
      localeName: partner?.locales[0]?.name || "",
      localeCode: partner?.locales[0]?.code || "",
    };

    if (partner) trackQuizLoad(metadata);
  }, [partner]);

  useEffect(() => {
    setRerenderLottie(0);
    setTimeout(() => {
      setRerenderLottie(new Date().getTime());
    }, 500);
  }, [isMobile]);

  return (
    <Container backgroundColor={quizHome?.backgroundColor}>
      {quizHome?.logoUrl && <StyledImage src={quizHome.logoUrl} alt={"Logo"} />}
      <Title text={quizHome?.title} tag={"h1"} />
      <Description text={quizHome?.description} tag={"p"} />
      {quizHome?.button?.label?.value && (
        <RoundedButton
          onClick={handleButtonClick}
          label={quizHome.button.label}
          fontColor={quizHome.button.label.fontColor}
          hoverBackgroundColor={quizHome?.buttonHoverBackgroundColor}
        />
      )}
      {!!rerenderLottie && haveLottie && (
        <LottieWrapper>
          <StyledLottie
            playing={playing}
            url={
              (isMobile
                ? quizHome?.bottomAnimation?.mobile
                : quizHome?.bottomAnimation?.desktop) as string
            }
            width={containerDimensions?.width}
            height={getWidth({
              containerDimensions,
              isMobile,
              value: isMobile ? 194 : 275,
            })}
            frame={10}
            onComplete={onComplete}
          />
        </LottieWrapper>
      )}
    </Container>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { NavigationBar } from "../components/shared/NavigationBar";
import { QuestionType } from "../types/quiz.d";
import { Route } from "../types/routes.d";
import { DropdownQuestion } from "../components/questions/DropdownQuestion";
import { SliderQuestion } from "../components/questions/SliderQuestion";
import { IllustrationQuestion } from "../components/questions/IllustrationQuestion";
import { MultipleChoiceQuestion } from "../components/questions/MultipleChoiceQuestion";
import {
  addActiveQuestion,
  removeActiveQuestion,
  removeSelectedAnswers,
} from "../store/quiz";
import { trackSelectedAnswer } from "../utils/analytics";

const Container = styled.div<{
  backgroundColor?: string;
  fullWidth?: boolean;
}>`
  background: ${({ backgroundColor }) => backgroundColor || "#518a70"};
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, fullWidth }) =>
    theme.isMobile
      ? `${theme.getValue(26)}px ${theme.getValue(23)}px ${theme.getValue(
          30
        )}px`
      : `${theme.getValue(96)}px ${theme.getValue(
          fullWidth ? 140 : 416
        )}px ${theme.getValue(56)}px`};
  gap: ${({ theme }) => `${theme.getValue(0)}px`};
  position: relative;
`;

export const Question = () => {
  const [containerHeight, setContainerHeight] = useState(0);
  const questions = useSelector((store: RootState) => store.quiz.questions);
  const activeQuestions = useSelector(
    (store: RootState) => store.quiz.activeQuestions
  );
  const activeQuestion = useMemo(() => {
    return activeQuestions[activeQuestions.length - 1];
  }, [activeQuestions]);

  const selectedAnswers = useSelector(
    (store: RootState) => store.quiz.selectedAnswers
  );
  const question = useSelector(
    (store: RootState) => store.quiz.questions[activeQuestion || 0]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const QuestionToRender = useMemo(() => {
    if (!question) {
      return null;
    }
    if (question?.type === QuestionType.Slider) {
      return <SliderQuestion key={question.id} />;
    }
    if (question?.type === QuestionType.Dropwdown) {
      return <DropdownQuestion key={question.id} />;
    }
    if (question?.type === QuestionType.Illustration) {
      return <IllustrationQuestion key={question.id} />;
    }
    if (question?.type === QuestionType.MultipleChoice) {
      return <MultipleChoiceQuestion key={question.id} />;
    }
    return null;
  }, [question]);

  useEffect(() => {
    function handleResize() {
      setContainerHeight(document.documentElement.clientHeight);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const getNextQuestionIndex = useCallback((): number | null => {
    if (!selectedAnswers) {
      return null;
    }
    if (selectedAnswers[question.id]?.length === 1) {
      const index = questions.findIndex(
        (q) => q.id === selectedAnswers[question.id][0].nextQuestionId
      );
      if (index !== -1) {
        return index;
      }
    }
    if (activeQuestion < questions.length - 1) {
      return activeQuestion + 1;
    }
    return null;
  }, [selectedAnswers, question, activeQuestion, questions]);

  const getPrevQuestionIndex = useCallback((): number | null => {
    if (activeQuestions.length > 1) {
      return activeQuestions[activeQuestions.length - 2];
    }
    return null;
  }, [activeQuestions]);

  const handleNextClick = useCallback(() => {
    const nextIndex = getNextQuestionIndex();

    for (let index = activeQuestion + 1; index < (nextIndex || 0); index++) {
      dispatch(removeSelectedAnswers(questions[index].id));
    }

    if (typeof nextIndex !== "number") {
      navigate(`${Route.Loading}`);
      return;
    }
    dispatch(addActiveQuestion(nextIndex));
    navigate(`${Route.Question}/${nextIndex}`);
  }, [getNextQuestionIndex, dispatch, navigate, questions, activeQuestion]);

  const handleNext = () => {
    const trackAnalytics = (questionId: string, allAnswers: any) => {
      const getTextValue = (a: any) => {
        if (a.text?.value) return a.text.value;
        if (a.title?.value) return a.title.value;
        if (a.label?.value) return a.label.value;

        return a.id;
      };
      trackSelectedAnswer(
        {
          uuid: questionId,
          selectedText: allAnswers[questionId]
            .map((v: any) => getTextValue(v))
            .join(","),
        },
        Object.values(allAnswers).map((ans: any, idx: number) => ({
          uuid: Object.keys(allAnswers)[idx],
          selectedText: ans.map((v: any) => getTextValue(v)).join(","),
        })) as any
      );
    };

    trackAnalytics(
      questions[activeQuestions[activeQuestions.length - 1]].id,
      selectedAnswers
    );
    handleNextClick();
  };

  const handlePrevClick = useCallback(() => {
    const prevIndex = getPrevQuestionIndex();
    dispatch(removeActiveQuestion(activeQuestion));
    if (typeof prevIndex !== "number") {
      navigate(`${Route.Home}/`);
      return;
    }
    navigate(`${Route.Question}/${prevIndex}`);
  }, [activeQuestion, getPrevQuestionIndex, dispatch, navigate]);

  const fullWidth = useMemo(() => {
    return question?.type === QuestionType.Illustration;
  }, [question]);

  return (
    <Container
      fullWidth={fullWidth}
      backgroundColor={question?.backgroundColor}
      style={{ height: containerHeight }}
    >
      {QuestionToRender}
      <NavigationBar
        currentStep={activeQuestion || 0}
        steps={questions.length}
        accentColor={question?.accentColor || "#ffffff"}
        onPrevClick={handlePrevClick}
        onNextClick={handleNext}
      />
    </Container>
  );
};

export default Question;

import { QuizState } from "../store/quiz";
import { MockedQuiz } from "../services/mock";
import { QuestionType } from "../types/quiz.d";
import { FontFamily } from "../types/fonts";

type BELabel = {
  uuid: string;
  name: string;
  text: string;
};

export const transformQuizFromBE = (data: any): QuizState => {
  const {
    quiz,
    partner,
    labels,
    quiz_article_links: quizArticleLinks,
    privacy_policy_link: privacyPolicyLink,
    terms_and_conditions_link: termsAndConditionsLink,
    check_box_on_page_load: checkboxOnPageLoad,
  } = data;

  const getLabel = (labelKey: string): string => {
    return labels.find((l: BELabel) => l.name === labelKey).text;
  };

  const getProductName = (name = ""): string | undefined => {
    const prodctName = name.toLowerCase().replace(/_/i, " ").split(" ");
    const prodctNameCapitalized = prodctName
      .map((a, index) => a.charAt(0).toUpperCase() + prodctName[index].slice(1))
      .join(" ");
    return prodctNameCapitalized;
  };

  const getAnswerWeights = (
    question: any,
    answerUuid: string
  ): Array<{
    productId: string;
    weight: number;
    disqualify?: boolean;
  }> => {
    const answerWithWeight = question?.answers_with_weights?.find(
      (aWithWeights: any) => {
        return aWithWeights.answer?.uuid === answerUuid;
      }
    );
    if (!answerWithWeight) {
      return [];
    }
    return answerWithWeight?.weights?.map((w: any) => ({
      productId: w.product_uuid,
      weight: w.weight,
      disqualify: !!w.disqualify,
    }));
  };
  const transformBanner = (article: any): Banner | undefined => {
    if (!article) {
      return undefined;
    }
    const currentArticle = quizArticleLinks.find(
      (qa: any) => qa.article.uuid === article.uuid
    );
    return {
      title: {
        value: article.title,
        fontFamily: article.title_font,
        fontColor: article.title_color,
      },
      image1: {
        src: article.image_url,
        alt: article.image_alt,
      },
      description: {
        value: article.description,
        fontFamily: article.description_font,
        fontColor: article.description_color,
      },
      button: {
        backgroundColor: article.button_copy_background_color,
        label: {
          value: article.button_copy,
          fontColor: article.button_copy_color,
        },
        link: currentArticle?.url || "",
      },
    };
  };
  const transformProducts = (q: any): Array<Product & Card> => {
    return q?.quiz_entities[0].products.map((p: any): Product & Card => {
      const { product } = p;
      const resultSection = q?.result_sections?.find(
        (rSection: any) =>
          rSection.product_kind_uuid === product?.product_kind?.uuid
      );
      return {
        id: product?.uuid as string,
        name: {
          value: product?.name as string,
        },
        image: product?.image_url as string,
        price: {
          value: product?.price as string,
        },
        salesPrice: {
          value: product?.sales_price as string,
        },
        disclaimer: {
          value: product?.footnote as string,
        },
        headerText: {
          value: resultSection?.card_header_title as string,
          fontColor: resultSection?.card_header_footer_text_color as string,
        },
        headerTextColor: resultSection?.card_header_footer_text_color as string,
        headerMobileImage: resultSection?.mobile_thumbnail_url as string,
        backgroundColor: resultSection?.card_background_color as string,
        headerBackgroundColor:
          resultSection?.card_header_footer_background_color as string,
        textColor: resultSection?.card_text_color as string,
        description: {
          value: product?.description as string,
        },
        highlights: product?.main_highlights?.map(
          (h: any): UIText => ({
            value: h?.name as string,
          })
        ),
        sku: product?.sku,
        category: {
          name: {
            value: product?.product_kind?.default_name as string,
          },
          image: product?.product_kind?.product_logo_url as string,
          shortName: {
            value: getProductName(product?.product_kind?.name) || "",
          },
        },
        links: {
          shopNow: p?.destination_link?.url as string,
          shopNowButtonCopy: q?.results_screen_shop_link_button_copy,
          shopNowButtonBackgroundColor:
            q?.results_screen_shop_link_background_color,
          shopNowButtonTextColor: q?.results_screen_shop_link_text_color,
          shopNowButtonBorderColor: q?.results_screen_shop_link_border_color,
          learnMore: p?.destination_link?.learn_more_url as string,
          learnMoreButtonCopy: q?.rslts_scr_lrn_mr_lnk_cpy,
          learnMoreButtonBackgroundColor:
            q?.results_screen_learn_more_link_background_color,
          learnMoreButtonTextColor:
            q?.results_screen_learn_more_link_text_color,
          learnMoreButtonBorderColor:
            q?.results_screen_learn_more_link_border_color,
          seeInAction: p?.destination_link?.additional_info_url as string,
          seeInActionButtonCopy: q?.rslts_scr_adnl_lnk_cpy,
          seeInActionButtonBackgroundColor:
            q?.results_screen_additional_link_background_color,
          seeInActionButtonTextColor:
            q?.results_screen_additional_link_text_color,
          seeInActionButtonBorderColor:
            q?.results_screen_additional_link_border_color,
        },
        showPrice: !!q?.results_screen_show_product_pricing,
        priceIconAlt: product?.program_logo_alt as string,
        priceIcon: product?.program_logo_url as string,
        priceDescription: {
          value: product?.use_purchase_program_feature
            ? (product?.description_of_program as string)
            : ("" as string),
        },
      };
    });
  };
  const transformQuestions = (
    q: any
  ): Array<
    | BaseQuestion
    | SliderQuestion
    | DropdownQuestion
    | IllustrationQuestion
    | MultipleChoiceQuestion
  > => {
    const transformBaseQuestion = (question: any): BaseQuestion => {
      const { quiz_question: qQuestion } = question;
      return {
        id: qQuestion.uuid as string,
        title: {
          value: qQuestion.title as string,
          fontFamily: qQuestion.title_text_font as FontFamily,
          fontColor: qQuestion.title_text_color,
        },
        subtitle: {
          value: qQuestion.subtitle as string,
          fontFamily: qQuestion.subtitle_font as FontFamily,
          fontColor: qQuestion.subtitle_text_color,
        },
        accentColor: (qQuestion.accent_color as string) || "#ffffff",
        isSingleAnswer: (qQuestion.answer_type as string) === "simple",
        backgroundColor: (qQuestion.background_color as string) || "#518A70", // ToDo: When proper support for UIText color is added, remove fixed value
        type: "", // Field is set with proper question type transformer
      };
    };
    const transformSliderQuestion = (question: any): SliderQuestion => {
      const baseQuestion = transformBaseQuestion(question);
      const { quiz_question: quizQuestion, answers } = question;
      return {
        ...baseQuestion,
        type: QuestionType.Slider,
        steps: answers.map((answer: any): SliderStep => {
          const { quiz_answer: qAnswer } = answer as any;
          const weights = getAnswerWeights(question, qAnswer?.uuid);
          return {
            showAnswerText: qAnswer?.show_text_on_slider,
            id: qAnswer?.uuid,
            title: {
              value: qAnswer?.answer,
              fontColor: qAnswer?.font_color,
            },
            subtitle: {
              value: qAnswer?.answer_line2,
              fontColor: qAnswer?.font_color,
            },
            productWeights: weights,
            nextQuestionId: answer.branching_question?.quiz_question?.uuid,
          };
        }),
        lottieAnimation:
          quizQuestion?.slider_animation_url || quizQuestion?.animation_url,
      };
    };

    const transformIllustrationQuestion = (
      question: any
    ): IllustrationQuestion => {
      const baseQuestion = transformBaseQuestion(question);
      const { answers } = question;
      return {
        ...baseQuestion,
        type: QuestionType.Illustration,
        options: answers.map((answer: any): IllustrationOption => {
          const { quiz_answer: qAnswer } = answer as any;
          const weights = getAnswerWeights(question, qAnswer?.uuid);
          return {
            id: qAnswer?.uuid,
            activeImage: qAnswer?.selected_state_image_url,
            nonActiveImage: qAnswer?.default_state_image_url,
            text: {
              value: qAnswer?.answer,
            },
            buttonColor: qAnswer?.button_color,
            buttonHoverColor: qAnswer?.button_hover_color,
            buttonTextColor: qAnswer?.button_text_color,
            productWeights: weights,
            nextQuestionId: answer.branching_question?.quiz_question?.uuid,
          };
        }),
      };
    };

    const transformMultipleChoiceQuestion = (
      question: any
    ): MultipleChoiceQuestion => {
      const baseQuestion = transformBaseQuestion(question);
      const { quiz_question: quizQuestion, answers } = question;
      return {
        ...baseQuestion,
        buttonSelectedTextColor: quizQuestion.button_selected_state_text_color,
        buttonSelectedBackgroundColor:
          quizQuestion.button_selected_state_background_color,
        image: "", // ToDo: Remove
        type: QuestionType.MultipleChoice,
        options: answers.map((answer: any): MultipleChoiceOption => {
          const { quiz_answer: qAnswer } = answer as any;
          const weights = getAnswerWeights(question, qAnswer?.uuid);
          return {
            id: qAnswer?.uuid,
            activeImage: qAnswer?.selected_state_image_url,
            nonActiveImage: qAnswer?.default_state_image_url,
            text: {
              value: qAnswer?.answer,
            },
            productWeights: weights,
            nextQuestionId: answer.branching_question?.quiz_question?.uuid,
          };
        }),
      };
    };

    const transformDropdownQuestion = (question: any): DropdownQuestion => {
      const baseQuestion = transformBaseQuestion(question);
      const { quiz_question: quizQuestion, answers } = question;
      return {
        ...baseQuestion,
        optionsTitle: {
          value: quizQuestion.subtitle,
          fontFamily: quizQuestion.subtitle_font,
          fontColor: quizQuestion.subtitle_text_color,
        },
        answerModalBackgroundColor: quizQuestion.answer_modal_background_color,
        answerModalSelectedColor:
          quizQuestion.answer_modal_selected_state_color,
        answerModalSubtitleColor: quizQuestion.answer_modal_subtitle_color,
        answerModalTextColor: quizQuestion.answer_modal_text_color,
        lottieAnimation: quizQuestion?.animation_url,
        type: QuestionType.Dropwdown,
        options: answers.map((answer: any): DropdownOption => {
          const { quiz_answer: qAnswer } = answer as any;
          const weights = getAnswerWeights(question, qAnswer?.uuid);
          return {
            id: qAnswer?.uuid,
            value: qAnswer?.answer,
            label: {
              value: qAnswer?.answer,
            },
            productWeights: weights,
            nextQuestionId: answer.branching_question?.quiz_question?.uuid,
          };
        }),
      };
    };

    return q.questions.map((question: any) => {
      if (question?.quiz_question.type === "popup_question") {
        return transformDropdownQuestion(question);
      }
      if (question?.quiz_question.type === "illustration_question") {
        return transformIllustrationQuestion(question);
      }
      if (question?.quiz_question.type === "slider_question") {
        return transformSliderQuestion(question);
      }
      if (question?.quiz_question.type === "multiple_choice_question") {
        return transformMultipleChoiceQuestion(question);
      }
      return transformBaseQuestion(question);
    });
  };
  return {
    home: {
      logoUrl: quiz?.start_screen_logo_url,
      backgroundColor: quiz?.start_screen_background_color,

      title: {
        value: quiz?.start_screen_title as string,
        fontFamily: quiz?.start_screen_title_font as FontFamily,
        fontColor: quiz?.start_screen_title_text_color,
      },
      description: {
        value: quiz?.start_screen_subtitle as string,
        fontFamily: quiz?.start_screen_subtitle_font as FontFamily,
        fontColor: quiz?.start_screen_subtitle_text_color,
      },
      button: {
        label: {
          value: quiz?.start_screen_button_copy as string,
          fontColor: quiz?.start_screen_button_text_color,
        },
      },
      buttonHoverBackgroundColor:
        quiz?.start_screen_button_text_hover_color as string,
      bottomAnimation: {
        desktop: quiz?.start_screen_robot_animation_desktop_url,
        mobile: quiz?.start_screen_robot_animation_mobile_url,
      },
    },
    labels: {
      next: getLabel("next"),
      signUp: getLabel("sign_up"),
      shopNow: getLabel("shop_the"),
      learnMore: getLabel("learn_more"),
      emailAddress: getLabel("email_address"),
      continue: getLabel("continue"),
      seeInAction: getLabel("see_in_action"),
    },
    loading: {
      showLoadingScreen: quiz?.informational_loading_screen_show,
      title: {
        value: quiz?.info_ld_scr_ttl as string,
        fontFamily: quiz?.informational_loading_screen_title_font as FontFamily,
        fontColor: quiz?.informational_loading_screen_title_color as string,
      },
      notLoadingText: {
        value: quiz?.info_ld_scr_aft_ld_ttl as string,
        fontFamily:
          quiz?.informational_loading_screen_after_load_title_font as FontFamily,
        fontColor:
          quiz?.informational_loading_screen_after_load_title_color as string,
      },
      subtitle: {
        value: quiz?.info_ld_scr_inf_cntr_ttl as string,
        fontFamily: quiz.info_ld_scr_inf_cntr_ttl_font as FontFamily,
        fontColor: quiz?.info_ld_scr_inf_cntr_ttl_color as string,
      },
      description: {
        value: quiz?.info_ld_scr_info_cont_desc as string,
        fontFamily: quiz?.info_ld_scr_info_cont_desc_font as FontFamily,
        fontColor: quiz?.info_ld_scr_info_cont_desc_color as string,
      },
      image: quiz?.info_ld_scr_info_cont_desc_image_url,
      animation: quiz?.informational_loading_screen_animation_image_url,
      duration: quiz?.informational_loading_screen_loading_time,
      backgroundColor: quiz?.informational_loading_screen_background_color,
      cardBackgroundColor:
        quiz?.info_ld_scr_information_container_background_color,
      nextCopy: {
        value: quiz?.info_ld_scr_gt_nxt_scr_cpy,
        fontColor:
          quiz?.informational_loading_screen_go_to_next_screen_copy_color,
        FontFamily:
          quiz?.informational_loading_screen_go_to_next_screen_copy_font,
      },
    },
    selectedAnswers: {},
    selectedAnswersProgress: {},
    activeQuestions: [],
    results: {
      title: {
        value: quiz?.results_screen_title as string,
        fontFamily: quiz?.results_screen_title_font as FontFamily,
        fontColor: quiz?.results_screen_title_color as string,
      },
      backgroundColor: quiz?.results_screen_background_color as string,
      mobileArticlesHeader: {
        value: quiz?.articles_header as string,
        fontFamily: quiz?.articles_header_font as FontFamily,
        fontColor: quiz?.articles_header_color as string,
      },
      banner1: transformBanner(quiz.articles[0]),
      banner2: transformBanner(quiz.articles[1]),
      signUp: {
        title: {
          value: quiz.email_col_form_sign_for_updt_title as string,
          fontFamily:
            quiz.email_col_form_sign_for_updt_title_text_font as FontFamily,
          fontColor:
            quiz.email_col_form_sign_for_updt_title_and_description_text_color,
        },
        showForm: quiz.email_col_form_sign_for_updt_show_email_updates,
        newsletterBackgroundColor:
          quiz.email_col_form_sign_for_updt_background_color as string,
        emailCopy: quiz.email_col_form_sign_for_updt_email_field_copy as string,
        emailSentColor:
          quiz.email_col_form_sign_for_updt_email_field_sent_color as string,
        optinCopy: quiz.eml_cl_frm_updt_shw_optin_chk_bx_cpy as string,
        signUpCopy: quiz.eml_cl_frm_updt_sbmt_btn_frm_cpy as string,
        description: {
          value: quiz.email_col_form_sign_for_updt_description as string,
          fontFamily:
            quiz.email_col_form_sign_for_updt_description_text_font as FontFamily,
          fontColor:
            quiz.email_col_form_sign_for_updt_title_and_description_text_color,
        },
        promotions: quiz.email_col_form_sign_for_updt_show_optin_check_box
          ? {
              value: quiz.eml_cl_frm_updt_shw_optin_chk_bx_cpy as string,
              fontFamily:
                quiz.email_col_form_sign_for_updt_title_text_font as FontFamily,
              fontColor:
                quiz.email_col_form_sign_for_updt_title_and_description_text_color,
            }
          : undefined,
        checkboxDefaultValue: checkboxOnPageLoad,
        legal1: {
          label: {
            value:
              quiz.eml_cl_form_for_updt_show_privacy_policy_lnk_cpy as string,
          },
          showLink: quiz.email_col_form_sign_for_updt_show_privacy_policy_link,
          link: privacyPolicyLink as string,
        },
        legal2: {
          label: {
            value: quiz.eml_cl_form_sv_rslts_shw_terms_condns_lnk_cpy as string,
          },
          showLink:
            quiz.email_col_form_sign_for_updt_show_terms_conditions_link,
          link: termsAndConditionsLink as string,
        },
      },
      share: {
        show: quiz.quiz_entities[0].results_show_share_with_friends,
        leftSection: {
          label: {
            value: "",
            fontFamily: "",
          },
          link: "",
          image: {
            src: "",
            alt: "",
          },
        },
        rightSection: {
          label: {
            value: "",
            fontFamily: "",
          },
          link: "",
          image: {
            src: "",
            alt: "",
          },
        },
      },
    },
    partner,
    products: transformProducts(quiz),
    questions: transformQuestions(quiz.quiz_entities[0]),
  } as QuizState;
};

export const transformQuizFromMocks = (data: MockedQuiz): QuizState => {
  return {
    home: {
      title: {
        value: data?.home?.title,
        fontFamily: data?.home?.titleFontFamily,
        fontWeight: data?.home?.titleFontWeight,
      },
      description: {
        value: data?.home?.description,
        fontFamily: data?.home?.descriptionFontFamily,
        fontWeight: data?.home?.descriptionFontWeight,
      },
      button: {
        label: {
          value: data?.home?.buttonLabel,
        },
        link: data?.home?.buttonLink,
      },
      bottomAnimation: data?.home?.bottomAnimation,
    },
    activeQuestions: [],
    selectedAnswers: {},
    selectedAnswersProgress: {},
    questions: data?.questions.map((q) => ({
      id: q.id,
      type: q.type,
      backgroundColor: q?.backgroundColor,
      title: {
        value: q.title,
        fontFamily: q.titleFontFamily,
        fontWeight: q.titleFontWeight,
      },
      subtitle: {
        value: q.subtitle,
        fontFamily: q.subtitleFontFamily,
        fontWeight: q.subtitleFontWeight,
      },
      lottieAnimation: q?.lottieAnimation,
      steps: q?.steps?.map((s) => ({
        id: s.id,
        title: {
          value: s.title,
          fontFamily: s.titleFontFamily,
          fontWeight: s.titleFontWeight,
        },
        subtitle: {
          value: s.subtitle,
          fontFamily: s.subtitleFontFamily,
          fontWeight: s.subtitleFontWeight,
        },
        productWeights: s.weights,
      })),
      image: q?.image,
      optionsTitle: q?.optionsTitle
        ? {
            value: q?.optionsTitle,
            fontFamily: q?.optionsTitleFontFamily,
            fontWeight: q?.optionsTitleFontWeight,
          }
        : undefined,
      options: q?.options?.map((o) => {
        if (q.type === QuestionType.Dropwdown) {
          return {
            id: o.id,
            value: o?.value,
            nextQuestionId: o?.nextQuestionId,
            productWeights: o.weights,
            label: {
              value: o?.title,
              fontFamily: o?.titleFontFamily,
              fontWeight: o?.titleFontWeight,
            },
          };
        }
        if (q.type === QuestionType.MultipleChoice) {
          return {
            id: o.id,
            activeImage: o.activeImage,
            nonActiveImage: o.nonActiveImage,
            nextQuestionId: o?.nextQuestionId,
            productWeights: o.weights,
            text: {
              value: o?.text,
              fontFamily: o?.textFontFamily,
              fontWeight: o?.textFontWeight,
            },
          };
        }
        if (q.type === QuestionType.Illustration) {
          return {
            id: o.id,
            activeImage: o.activeImage,
            nonActiveImage: o.nonActiveImage,
            nextQuestionId: o?.nextQuestionId,
            productWeights: o.weights,
            text: {
              value: o?.text,
              fontFamily: o?.textFontFamily,
              fontWeight: o?.textFontWeight,
            },
          };
        }
      }),
    })),
    loading: {
      title: {
        value: data?.loading?.title,
        fontFamily: data?.loading?.titleFontFamily,
        fontWeight: data?.loading?.titleFontWeight,
      },
      subtitle: {
        value: data?.loading?.subtitle,
        fontFamily: data?.loading?.subtitleFontFamily,
        fontWeight: data?.loading?.subtitleFontWeight,
      },
      description: {
        value: data?.loading?.description,
        fontFamily: data?.loading?.descriptionFontFamily,
        fontWeight: data?.loading?.descriptionFontWeight,
      },
      notLoadingText: {
        value: data?.loading?.notLoadingText,
        fontFamily: data?.loading?.notLoadingTextFontFamily,
        fontWeight: data?.loading?.notLoadingTextFontWeight,
      },
      image: data?.loading?.image,
      animation: data?.loading?.animation,
      duration: 5,
    },
    labels: {
      next: data?.labels?.next,
      learnMore: data?.labels?.learnMore,
      seeInAction: data?.labels?.seeInAction,
      shopNow: data?.labels?.shopNow,
      continue: data?.labels?.continue,
      emailAddress: data?.labels?.emailAddress,
      signUp: data?.labels?.signUp,
    },
    results: {
      title: {
        value: data?.results?.title,
        fontFamily: data?.results?.titleFontFamily,
        fontWeight: data?.results?.titleFontWeight,
      },
      banner1: {
        title: {
          value: data?.results?.banner1Title,
          fontFamily: data?.results?.banner1TitleFontFamily,
          fontWeight: data?.results?.banner1TitleFontWeight,
        },
        description: {
          value: data?.results?.banner1Description,
          fontFamily: data?.results?.banner1DescriptionFontFamily,
          fontWeight: data?.results?.banner1DescriptionFontWeight,
        },
        button: {
          label: {
            value: data?.results?.banner1ButtonLabel,
          },
          link: data?.results?.banner1ButtonLink,
        },
        image1: {
          src: data?.results?.banner1Image1Url,
          alt: data?.results?.banner1Image1Alt,
        },
        image2: {
          src: data?.results?.banner1Image2Url,
          alt: data?.results?.banner1Image2Alt,
        },
      },
      banner2: {
        title: {
          value: data?.results?.banner2Title,
          fontFamily: data?.results?.banner2TitleFontFamily,
          fontWeight: data?.results?.banner2TitleFontWeight,
        },
        description: {
          value: data?.results?.banner2Description,
          fontFamily: data?.results?.banner2DescriptionFontFamily,
          fontWeight: data?.results?.banner2DescriptionFontWeight,
        },
        button: {
          label: {
            value: data?.results?.banner2ButtonLabel,
          },
          link: data?.results?.banner2ButtonLink,
        },
        image1: {
          src: data?.results?.banner2Image1Url,
          alt: data?.results?.banner2Image1Alt,
        },
      },
      share: {
        leftSection: {
          image: {
            src: data?.results?.leftSectionImage,
            alt: data?.results?.leftSectionTitle,
          },
          link: data?.results?.leftSectionLink,
          label: {
            value: data?.results?.leftSectionTitle,
            fontFamily: data?.results?.leftSectionTitleFontFamily,
            fontWeight: data?.results?.leftSectionTitleFontWeight,
          },
        },
        rightSection: {
          image: {
            src: data?.results?.rightSectionImage,
            alt: data?.results?.rightSectionTitle,
          },
          link: data?.results?.rightSectionLink,
          label: {
            value: data?.results?.rightSectionTitle,
            fontFamily: data?.results?.rightSectionTitleFontFamily,
            fontWeight: data?.results?.rightSectionTitleFontWeight,
          },
        },
      },
      signUp: {
        title: {
          value: data?.results?.signUpTitle,
          fontFamily: data?.results?.signUpTitleFontFamily,
          fontWeight: data?.results?.signUpTitleFontWeight,
        },
        description: {
          value: data?.results?.signUpDescription,
          fontFamily: data?.results?.signUpDescriptionFontFamily,
          fontWeight: data?.results?.signUpDescriptionFontWeight,
        },
        legal1: {
          label: {
            value: data?.results?.signUpLegal1Label,
            fontFamily: data?.results?.signUpLegal1LabelFontFamily,
            fontWeight: data?.results?.signUpLegal1LabelFontWeight,
          },
          link: data?.results?.signUpLegal1Link,
        },
        legal2: {
          label: {
            value: data?.results?.signUpLegal1Label,
            fontFamily: data?.results?.signUpLegal1LabelFontFamily,
            fontWeight: data?.results?.signUpLegal1LabelFontWeight,
          },
          link: data?.results?.signUpLegal1Link,
        },
        promotions: {
          value: data?.results?.signUpLegalPromotionsLabel,
          fontFamily: data?.results?.signUpLegalPromotionsLabelFontFamily,
          fontWeight: data?.results?.signUpLegalPromotionsLabelFontWeight,
        },
      },
    },
    products: data?.products?.map((product) => ({
      category: {
        name: {
          value: product?.category?.name,
        },
        image: product?.category?.image,
        shortName: {
          value: product?.category?.shortName,
        },
      },
      name: {
        value: product?.name,
      },
      description: {
        value: product?.description,
      },
      image: product?.image,
      showPrice: true,
      price: {
        value: product?.price || "",
      },
      priceDescription: {
        value: product?.priceDescription || "",
      },
      priceIcon: product?.priceIcon,
      disclaimer: {
        value: product?.disclaimer || "",
      },
      highlights: product?.highlights?.map((h) => ({
        value: h,
      })),
      id: product?.id,
      textColor: product?.textColor,
      backgroundColor: product?.backgroundColor,
      headerBackgroundColor: product?.headerBackgroundColor,
      headerTextColor: product?.headerTextColor,
      links: {
        learnMore: product?.links?.learnMore,
        shopNow: product?.links?.shopNow,
        seeInAction: product?.links?.seeInAction,
      },
    })),
  };
};

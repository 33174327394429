import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { RootState } from "../store";
import { setContainerDimensions } from "../store/ui";
import { ROOT_ID } from "../utils/constants";

export const useContainerDimensions = (): { height: number; width: number } => {
  const dispatch = useDispatch();
  const containerDimensions = useSelector(
    (state: RootState) => state.ui.container
  );
  useEffect(() => {
    function handleResize() {
      const container = document.getElementById(ROOT_ID);
      dispatch(
        setContainerDimensions({
          width: container?.clientWidth || 0,
          height: container?.clientHeight || 0,
        })
      );
    }
    window.addEventListener("resize", debounce(handleResize, 300));
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
  return containerDimensions;
};

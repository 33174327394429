import React from "react";
import styled from "styled-components";

const SVG = styled.svg``;

type CheckSVGProps = {
  color: string;
  className?: string;
};

export const CheckSVG = ({ color, className }: CheckSVGProps) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="12.742"
      height="11.271"
      viewBox="0 0 12.742 11.271"
      className={className}
    >
      <path
        id="Check"
        d="M121.483,258.884l3.056,3.25,7.495-8.053"
        transform="translate(-120.39 -253.059)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </SVG>
  );
};

export default CheckSVG;

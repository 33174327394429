import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { getWidth } from "./utils/ui";
// eslint-disable-next-line import/no-named-as-default
import Lottie from "./components/shared/Lottie";
import { useContainerDimensions } from "./hooks/useContainerDimensions";
import { useQuizData } from "./hooks/useQuizData";
import { Route as AppRoute } from "./types/routes.d";
import { Question } from "./screens/Question";
import { Home } from "./screens/Home";
import { Loading } from "./screens/Loading";
import { Results } from "./screens/Results";
import { ROOT_ID } from "./utils/constants";
import { useDeviceDetector } from "./hooks/useDeviceDetector";

type Theme = {
  container: {
    height: number;
    width: number;
  };
  getValue: (value: number) => number;
  getFontSize: (value: number) => number;
  isMobile: boolean;
};

const Wrapper = styled.div<{ isQr: boolean; containerHeight: number }>`
  height: ${({ isQr, theme, containerHeight }) =>
    isQr && !theme.isMobile ? `${containerHeight}px` : "100%"};
`;

const PortraitMode = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

function App() {
  const containerDimensions = useContainerDimensions();
  const { isMobile } = useDeviceDetector();
  const [windowHeight, setWindowHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
      setContainerWidth(
        document.documentElement.clientWidth || document.body.clientWidth
      );
      setContainerHeight(
        document.documentElement.clientHeight || document.body.clientHeight
      );
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useQuizData();

  const isPortrait = !isMobile && containerHeight > containerWidth;

  const getValue = useCallback(
    (value: number): number => {
      return getWidth({
        containerDimensions,
        isMobile,
        value,
      });
    },
    [containerDimensions, isMobile]
  );

  const theme = useMemo(
    (): Theme => ({
      container: containerDimensions,
      getValue,
      getFontSize: getValue,
      isMobile,
    }),
    [containerDimensions, getValue, isMobile]
  );

  const quizTagData = document?.getElementById(ROOT_ID)?.dataset;

  return (
    <ThemeProvider theme={theme}>
      <Wrapper
        containerHeight={windowHeight}
        isQr={Boolean(quizTagData?.isQr)}
        className="App"
      >
        {isPortrait ? (
          <PortraitMode>
            <Lottie
              width={getWidth({
                containerDimensions,
                isMobile,
                value: 1150,
              })}
              height={getWidth({
                containerDimensions,
                isMobile,
                value: 1063,
              })}
              url={`${quizTagData?.buildUrl}/assets/lottie/45792-rotate-phone.json`}
              autoplay={true}
              loop
              frame={1}
            />
          </PortraitMode>
        ) : (
          <Router initialEntries={["/"]}>
            <Routes>
              <Route path={`${AppRoute.Question}/:id`} element={<Question />} />
              <Route path={AppRoute.Home} element={<Home />} />
              <Route path={AppRoute.Loading} element={<Loading />} />
              <Route path={AppRoute.Results} element={<Results />} />
            </Routes>
          </Router>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;

import styled from "styled-components";
import { Text } from "./Text";
import { Image } from "./Image";
import { useCallback } from "react";

const Container = styled.button<{
  direction: "LEFT" | "RIGHT";
  active: boolean;
  buttonColor: string;
  buttonHoverColor: string;
}>`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(302)}px` : `${theme.getValue(536)}px`};
  border: none;
  border-radius: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(10)}px` : `${theme.getValue(10)}px`};
  padding: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(20)}px` : `${theme.getValue(37)}px`};
  display: flex;
  justify-content: ${({ direction }) =>
    direction === "LEFT" ? "flex-start" : "flex-end"};
  background-color: ${({ active, buttonHoverColor, buttonColor }) =>
    active ? buttonHoverColor : buttonColor};
  align-items: center;
  position: relative;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.5s;

  &:hover {
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};
  }
`;

const StyledText = styled(Text)<{ buttonTextColor: string }>`
  font-weight: 400;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? `${theme.getFontSize(14)}px`
      : `${theme.getFontSize(24)}px`};
  max-width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(160)}px` : `${theme.getValue(331)}px`};
  text-align: center;
  margin: 0;

  &&& {
    color: ${({ buttonTextColor }) => buttonTextColor};
  }
`;

const StyledImage = styled(Image)<{
  direction: "LEFT" | "RIGHT";
  active: boolean;
}>`
  width: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(100)}px` : `${theme.getValue(177)}px`};
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.getValue(100)}px` : `${theme.getValue(177)}px`};
  object-fit: contain;
  position: absolute;
  right: ${({ direction }) => (direction === "LEFT" ? 0 : "unset")};
  left: ${({ direction }) => (direction === "RIGHT" ? 0 : "unset")};
  top: 50%;
  transform: translateY(-50%);
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition-property: opacity;
  transition-duration: 0.5s;
`;

type GraphicToggleProps = {
  text: UIText;
  isActive: boolean;
  onClick: (selected: boolean) => void;
  activeImage: string;
  nonActiveImage: string;
  direction: "LEFT" | "RIGHT";
  buttonColor: string;
  buttonHoverColor: string;
  buttonTextColor: string;
};

export const GraphicToggle = ({
  activeImage,
  nonActiveImage,
  text,
  isActive,
  onClick,
  direction,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
}: GraphicToggleProps) => {
  const handleClick = useCallback(() => {
    onClick(!isActive);
  }, [onClick, isActive]);

  return (
    <Container
      buttonColor={buttonColor}
      buttonHoverColor={buttonHoverColor}
      active={isActive}
      direction={direction}
      onClick={handleClick}
    >
      <StyledImage
        active={!isActive}
        src={nonActiveImage}
        alt={text?.value}
        direction={direction}
      />
      <StyledImage
        active={isActive}
        src={activeImage}
        alt={text?.value}
        direction={direction}
      />
      <StyledText buttonTextColor={buttonTextColor} text={text} tag={"p"} />
    </Container>
  );
};

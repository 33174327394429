// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UIState {
  container: {
    height: number;
    width: number;
  };
}

const initialState: UIState = {
  container: {
    height: 0,
    width: 0,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setContainerDimensions: (
      state,
      action: PayloadAction<{
        height: number;
        width: number;
      }>
    ) => {
      const payload = action.payload;
      state.container.height = payload.height;
      state.container.width = payload.width;
    },
  },
});

export const { setContainerDimensions } = uiSlice.actions;

export default uiSlice.reducer;

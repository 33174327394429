import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useCallback, useEffect, useState } from "react";
import { QuizState, setQuizData } from "../store/quiz";
import {
  transformQuizFromBE,
  transformQuizFromMocks,
} from "../utils/transform";
import { QUIZ_MOCKED_API_RESPONSE } from "../services/mock";
import { fetchExperience } from "../services/api";

export const useQuizData = (shouldFetch = true) => {
  const dispatch = useDispatch();
  const quizData = useSelector((state: RootState) => state.quiz);
  const [fetched, setFetched] = useState(!shouldFetch);

  const fetchDataFromBE = useCallback(async (): Promise<QuizState> => {
    const shouldUseMockedData = process.env.REACT_APP_USE_MOCK === "true";
    if (shouldUseMockedData) {
      return transformQuizFromMocks(QUIZ_MOCKED_API_RESPONSE);
    }
    const { apiUrl, experienceId } = document.getElementById(
      "irobot-embedded-quiz-v4"
    )?.dataset as { apiUrl: string; experienceId: string };
    const experience = await fetchExperience(apiUrl, experienceId);
    return transformQuizFromBE(experience);
  }, []);

  useEffect(() => {
    if (!fetched) {
      fetchDataFromBE().then((data) => {
        setFetched(true);
        dispatch(setQuizData(data));
      });
    }
  }, [setFetched, dispatch, fetchDataFromBE, fetched]);

  return quizData;
};

export default useQuizData;
